import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';

Modal.setAppElement('#root')

class PostModal extends React.Component {

    constructor() {
        super();
       this.state = {
        id: null,
        inputTitle : '',
        inputContent : '',
       }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const postObject = {
            _id: this.state.id,
            title:this.state.inputTitle,
            content: this.state.inputContent,
        }

       this.props.editMode? store.dispatch(actions.post.editPost(postObject)) : store.dispatch(actions.post.addPost(postObject));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            id:nextProps.post?._id,
            inputTitle:nextProps.post?.title,
            inputContent:nextProps.post?.content,
        })
      }

    closeModal() {
        store.dispatch(actions.post.postError(null));
        store.dispatch(actions.post.openCloseModal());
      }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render() {
    return (
       <div>
        <Modal
          isOpen={store.getState().post.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                   <h6 className="c-grey-900">{this.props.modalTitle}</h6>
                    <div className="masonry-item col-md-12">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="inputTitle">Title</label>
                                <input type="name" className="form-control" required id="inputTitle" aria-describedby="nameHelp" placeholder="Enter title" defaultValue={this.state.inputTitle}  onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputContent">Content</label>
                                <input type="text" className="form-control" id="inputContent" placeholder="Enter content" defaultValue={this.state.inputContent} onChange={this.onChange}></input>
                            </div> 
                          {!IsNullOrEmptyOrUndefined(store.getState().post.error)?
<div className="alert alert-danger align-to-center max-width-600" role="alert">
<span>{store.getState().post.error}</span>
                    </div> : ''}
                    <div className={this.props.editMode? "control-buttons" : "control-buttons justify-to-right"}>
                        <button type="button" className={this.props.editMode? "btn btn-danger" : "hidden" } onClick={() => store.dispatch(actions.post.removePost(this.state.id))}>Remove</button>
                        <span className="control-buttons">
                        <button type="button" className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </span>
                    </div>
                        </form>
                    </div>
                </div>
        </Modal>
        </div>
    )
 }
}
export default PostModal;