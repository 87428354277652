import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import { makeid} from '../../utils/helpers/dataHelper.js';


Modal.setAppElement('#root')

class CrewUsers extends React.Component {


    constructor() {
        super();
        this.state = {
          users:IsNullOrEmptyOrUndefined(store.getState().user?.data)? [] : store.getState().user?.data,
          selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
          crewUsers:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.users)? [] : this.convertUserData(store.getState().crew.selectedCrew?.users),
          modalIsOpen:false,
          editMode:false,
          modalTitle:'',
          selectedUser:{},
          id:'',
          inputUser:'',
          inputRole:'owner',
          editingUser:false,
        }


    }

    componentDidMount() {
      store.subscribe(() => {
          this.setState({
            users:IsNullOrEmptyOrUndefined(store.getState().user?.data)? [] : store.getState().user?.data,
            selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
            crewUsers:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.users)? [] : this.convertUserData(store.getState().crew.selectedCrew?.users),
           })
      });
  }


      convertUserData(data) {
        const users = IsNullOrEmptyOrUndefined(store.getState().user?.data)? [] : store.getState().user?.data;
        const userObjects = data?.map(item => {
          return {_id: IsNullOrEmptyOrUndefined(item.user)? item._id: item.user, email: IsNullOrEmptyOrUndefined(item.user)? item.email: users.filter(user => user._id === item.user)[0]?.email, role:item.role, id:item._id}
        })
           return userObjects.filter(user => !IsNullOrEmptyOrUndefined(user.email));
         }

      showUser(id) {
          this.setState(prevState => {
            const selectedUser = prevState.crewUsers.filter(user =>
                user._id === id
            )[0]

            return { selectedUser };
        })

        this.openModal('Editing exisitng user', true)
      }

      openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
         this.setState({
             modalIsOpen:true
         })
      }

      closeModal() {
        this.setState({
            modalIsOpen:false,
            selectedUser:{}
        })
      }

      addUser() {

        var users = this.state.crewUsers;
        users.push({id:makeid(), user:this.state.inputUser, role:this.state.inputRole});
        this.setState({crewUsers: users});

        let modifingCrew = this.state.selectedCrew;
        modifingCrew.users = users.map(user => {return { _id: IsNullOrEmptyOrUndefined(user._id)? user.id : user._id, user: IsNullOrEmptyOrUndefined(user.user)? user._id: user.user, role:user.role}})

        store.dispatch(actions.crew.setCrew(modifingCrew));
        this.closeModal();
      }

      removeUser() {
          
         const users = this.state.crewUsers.filter(user => user._id !== this.state.selectedUser._id);
         this.setState({crewUsers: users});

         let modifingCrew = this.state.selectedCrew;
         modifingCrew.users = users.map(user => {return { _id: IsNullOrEmptyOrUndefined(user._id)? user.id : user._id, user: IsNullOrEmptyOrUndefined(user.user)? user._id: user.user, role:user.role}})

         store.dispatch(actions.crew.setCrew(modifingCrew));
         this.closeModal();
      }

      renderUserList = () => this.state.crewUsers.map((user) => (
        <tr id={user?._id} key={user?._id} onClick={() => this.showUser(user?._id)}>
            <td>{user?.email}</td>
            <td>{user?.role}</td>
        </tr>
      )); 


      onChange = e => this.setState({ [e.target.id]: e.target.value })

    render() {
    return (
<div>

<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.state.modalTitle}</h6>
                    <div className="mT-30">
                        <div className="form-group">
                                <label htmlFor="inputUser">User</label>
                                <select id="inputUser" className="form-control" required  onChange={this.onChange} disabled={this.state.editMode} defaultValue={this.state.selectedUser._id}>
                                    <option key={-1} value={-1} >--select user--</option>
                                    {this.state.users.map((item) => 
                                        <option key={item._id} value={item._id}>{item.email}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputRole">Role</label>
                                <select id="inputRole" className="form-control" onChange={this.onChange} disabled={this.state.editMode} defaultValue={ this.state.selectedUser.role}>
                                    <option value="owner">owner</option>
                                    <option value="member">member</option>
                                </select>
                            </div>

                            <div className={this.state.editMode? "control-buttons" : "control-buttons justify-to-right"} >
                            <button className={this.state.editMode? "btn btn-danger" : "hidden" } onClick={() => this.removeUser()}>Remove</button>
                              <span className="control-buttons"> 
                               <button className="btn btn-primary" type="button" onClick={() => this.closeModal()}>Cancel</button>
                               <button type="button" className={!this.state.editMode? "btn btn-primary" : "hidden" } onClick={() => this.addUser()}>Add</button>
                              </span>
                            </div>
                    </div>
                </div>
            </Modal>

    <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new user', false)}>Add User</button>
                </div>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                    {this.renderUserList()}
                      </tbody>
                    </table>
               </div>
              </div>
            </div>


    </div>
    </div>
    )
 }
}

export default CrewUsers;