import { toast } from 'react-toastify';
import { actions } from '../actions.js';

const INITIAL_STATE = {
    loading: true,
    error: null,
    data: null,
};

export const organization = (state, action) => {
    switch (action.type) {
        case actions.organization.GET_ALL_ORGANIZATIONS:
            return {
                ...state,
                organizationResults: {
                    data: [],
                    loading: true,
                    error: null
                }
            };
        case actions.organization.SET_ALL_ORGANIZATIONS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            }
        case actions.organization.EDIT_ORGANIZATION:
            toast.success("Organization updated with success!");
                return {
                    ...state,
                data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                }
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
            };
        case actions.organization.ORGANIZATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return {
                ...state
            }
    }
}