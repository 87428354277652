export const GET_ALL_EVENTS = 'event/GET_ALL_EVENTS';
export const SET_ALL_EVENTS = 'event/SET_ALL_EVENTS';
export const RESET_EVENT = 'event/RESET_EVENT';
export const ADD_EVENT = 'event/ADD_EVENT';
export const REMOVE_EVENT = 'event/REMOVE_EVENT';
export const EDIT_EVENT = 'event/EDIT_EVENT';
export const SEARCH_EVENT = 'event/SEARCH_EVENT';
export const EVENT_ERROR = 'event/EVENT_ERROR';
export const ACTION_ERROR = 'event/ACTION_ERROR';
export const OPEN_CLOSE_MODAL = 'event/OPEN_CLOSE_MODAL';
export const EVENT_ADDED_SUCCESS = 'event/EVENT_ADDED_SUCCESS';
export const EVENT_UPDATED_SUCCESS = 'event/EVENT_UPDATED_SUCCESS';
export const EVENT_REMOVED_SUCCESS = 'event/EVENT_REMOVED_SUCCESS';

export const getAllEvents = () => ({
    type:GET_ALL_EVENTS
})

export const setAllEvents = (payload) => ({
    type:SET_ALL_EVENTS,
    payload
})

export const resetEvent = () => ({
    type:RESET_EVENT
})

export const addEvent = (payload) => ({
    type:ADD_EVENT,
    payload
})

export const editEvent = (payload) => ({
    type:EDIT_EVENT,
    payload
})

export const removeEvent = (payload) => ({
    type:REMOVE_EVENT,
    payload
})

export const searchEvent = (payload) => ({
    type:SEARCH_EVENT,
    payload
})

export const eventAddedSuccess = (payload) => ({
    type: EVENT_ADDED_SUCCESS,
    payload
})

export const eventUpdatedSuccess = (payload) => ({
    type: EVENT_UPDATED_SUCCESS,
    payload
})

export const eventRemovedSuccess = (payload) => ({
    type: EVENT_REMOVED_SUCCESS,
    payload
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const eventError = (payload) => ({
    type: EVENT_ERROR,
    payload
});

export const actionError = (payload) => ({
    type: ACTION_ERROR,
    payload
});