import React from 'react';
import './styles/main.css';
import Sidebar from './components/core/Sidebar';
import Topbar from './components/core/Topbar';
import Footer from './components/core/Footer';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './components/home/Home';
import Users from './components/users/Users';
import Posts from './components/posts/Posts';
import Campings from './components/campings/Campings';
import Crew from './components/crew/Crew';
import CrewMembers from './components/crew/CrewMembers';
import CrewContacts from './components/crew/CrewContacts';
import Events from './components/events/Events';
import store from './store/store.js';
import { actions } from './store/actions.js';
import Login from './components/login/Login.js';
import { checkIfExistingTokenIsValid } from './utils/helpers/authHelper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class App extends React.Component {

  state = {
    authenticated: false,
    errorMessage:'',
    previousLoginAttemptFailed: false,
  };  

  constructor() {
    super();
    store.dispatch(actions.global.resetApp());
  }

  componentDidMount() {
    store.subscribe(() => {
        this.setState({
        authenticated: store.getState().auth?.authenticated,
        errorMessage: store.getState().auth?.error,
        previousLoginAttemptFailed: store.getState().auth?.previousLoginAttemptFailed,
         })
    });
}


  isUserLoginIn() {
    return store.getState().auth?.authenticated;
  }

  render() {
  return (
    <div id='root'>
      {this.isUserLoginIn() && checkIfExistingTokenIsValid()?
      <div>
      <Router>
        <Sidebar />

        <div className="page-container">
          <Topbar />
          <main className='main-content bgc-grey-100'>
            <div id='mainContent'>
              <Route path='/' exact component={Home} />
              <Route path='/users' component={Users} />
              <Route path='/posts' component={Posts} />
              <Route path='/campings' component={Campings} />
              <Route path='/teams' component={Crew} />
              <Route path='/events' component={Events} />
              <Route  path='/crew-members'  component={CrewMembers} />
              <Route  path='/crew-contacts' component={CrewContacts} />
            </div>
          </main>
        </div>
      </Router>


      <Footer /> 
      </div>: <div><Login errorMessage = {this.state.errorMessage} previousLoginAttemptFailed = {this.state.previousLoginAttemptFailed} /></div>
  }
         <ToastContainer />
    </div>
  )
  }
}

export default App;
