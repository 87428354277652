import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

const getAllCampingsEpic = (action$) =>
  action$.pipe(
    ofType(actions.camping.GET_ALL_CAMPINGS),
    mergeMap(() => {
      return AxiosRallyGoAPI.campings.getAllCampings()
        .then(response => actions.camping.setAllCampings(response.data))
        .catch(error => actions.camping.campingError(error?.response?.data?.message));
    }),
  );

  const addCampingsEpic = (action$) =>
  action$.pipe(
    ofType(actions.camping.ADD_CAMPING),
    mergeMap(action => {
      return AxiosRallyGoAPI.campings.addCamping(action.payload)
        .then(response => actions.camping.campingAddedSuccess(response.data))
        .catch(error => actions.camping.actionError(error?.response?.data?.message));
    }),
  );

  const editCampingsEpic = (action$) =>
  action$.pipe(
    ofType(actions.camping.EDIT_CAMPING),
    mergeMap(action => {
      return AxiosRallyGoAPI.campings.editCamping(action.payload)
        .then(response => actions.camping.campingUpdatedSuccess(action.payload))
        .catch(error => actions.camping.actionError(error?.response?.data?.message));
    }),
  );

  const removeCampingsEpic = (action$) =>
  action$.pipe(
    ofType(actions.camping.REMOVE_CAMPING),
    mergeMap(action => {
      return AxiosRallyGoAPI.campings.removeCamping(action.payload)
        .then(response => actions.camping.campingRemovedSuccess(action.payload._id))
        .catch(error => actions.camping.actionError(error?.response?.data?.message));
    }),
  );

  export default combineEpics(getAllCampingsEpic, addCampingsEpic, removeCampingsEpic, editCampingsEpic);