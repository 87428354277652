import * as user from './user/userActions.js';
import * as modal from './modal/modalActions.js';
import * as camping from './camping/campingActions.js';
import * as event from './event/eventActions.js';
import * as crew from './crew/crewActions.js';
import * as organization from './organization/organizationActions.js';
import * as post from './post/postActions.js';
import * as global from './globalActions.js';
import * as auth from './auth/authActions.js';

export const actions = {
    user,
    modal,
    camping,
    event,
    crew,
    organization,
    post,
    global,
    auth,
};