import React from 'react';
import '../shared/common.scss';
import UserModal from './UserModal.js';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import Pagination from 'react-js-pagination';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';

class Users extends React.Component {
  _isMounted = false;

  state = {
    pageSize:10,
    activePage:1,
    sliceFrom:0,
    selectedRowId:null,
    selectedUser:null,
    modalTitle:'',
    editMode: false,
    userRoles:[],
    allCrew:[],
    allOrganizations:[],
    modifiedCrew:null,
    modifiedOrganization: null,
  }; 

    componentDidMount() {
      this._isMounted = true;
      store.dispatch(actions.user.getAllUsers());
      store.dispatch(actions.crew.getAllCrew());
      store.dispatch(actions.organization.getAllOrganizations());
        store.subscribe(() => {
          if (this._isMounted) {
            this.setState({
            allCrew:IsNullOrEmptyOrUndefined(store.getState().crew.data)? [] : store.getState().crew.data, 
            allOrganizations:IsNullOrEmptyOrUndefined(store.getState().organization.data)? [] : store.getState().organization.data, 
            data:store.getState().user.filteredData,
            userRoles:IsNullOrEmptyOrUndefined(store.getState().user.userRoles)? [] : store.getState().user.userRoles,
            modifiedCrew: store.getState().user.userCrew,
            modifiedOrganization: store.getState().user.userOrganizations
             })
            }
        });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({selectedUser:null, editMode:false});
        } else {
             store.dispatch(actions.user.setUser(this.state.selectedUser));
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
          store.dispatch(actions.user.openCloseModal());
      }

    clickRow(id) {
      this.openModal('Editing existing user', true);
        
        this.setState(prevState => {

            const selectedUser = prevState.data.filter(usr =>
                usr._id === id
            )[0]
            return { selectedUser };
        })

        store.dispatch(actions.user.getUserRoles(id));
    }

    renderUserList = () => this.state.data?.slice(this.state.sliceFrom,this.state.sliceFrom + this.state.pageSize).map((user, index) => (
      <tr id={user._id} key={user._id} onClick={() => this.clickRow(user._id)} className="data-row">
          <td>{user.email}</td>
          <td>{user.fname}</td>
          <td>{user.lname}</td>
          <td>{user.status}</td>
      </tr>
    )); 

    handlePageChange(pageNumber) {
        this.setState(prevState => {
            return {
                ...prevState,
                activePage: pageNumber,
                sliceFrom: (pageNumber * prevState.pageSize) - prevState.pageSize
            }

        });
    }

    removeUser() {
        store.dispatch(actions.user.removeUser(this.props.id));
    }

    search(e) {
        this.handlePageChange(1);
        let value = '';
        value += e.target.value;
        store.dispatch(actions.user.searchUser(value));
      }

    setUserCrew(data) {
        const userCrew = data?.filter(crew => crew.users?.some(user => user?.user === this.props.user?._id));

        let userCrewObject = [];
         userCrew.map(crew => {
         return userCrewObject.push(
               {
                   role:crew.users.filter(user => user.user === this.props.user?._id)[0]?.role,
                   crewToUpdate:crew
               }
           )
        })

        return userCrewObject;
    }
    
    render() {
    return (
      <main className='main-content bgc-grey-100' id="root">
        <div id='mainContent'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                  <div className="control-panel">
                    <div className="control-buttons">
                      <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new user', false)}>Add</button>
                    </div>
                    <input type="search" placeholder="Search..." onInput={(e) => this.search(e)}></input>
                  </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                    <thead>
                      <tr>
                        <th>E-mail</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {store.getState().user.loading ?
                        <tr><td colSpan="4" className="align-to-center"> <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        </td>
                        </tr>
                        :
                        this.renderUserList()}
                    </tbody>
                  </table>
                  <div className="table-footer">
                    <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing {this.state.activePage} to {this.state.pageSize} of {this.state.data?.length} entries</div>
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={this.state.activePage}
                      itemsCountPerPage={IsNullOrEmptyOrUndefined(this.state.pageSize)? 0 : this.state.pageSize}
                      totalItemsCount={IsNullOrEmptyOrUndefined(this.state.data?.length)? 0 : this.state.data?.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserModal
          user={this.state.selectedUser}
          roles={this.state.userRoles}
          allCrew={this.state.allCrew}
          allOrganizations={this.state.allOrganizations}
          modalTitle={this.state.modalTitle}
          editMode={this.state.editMode}
          updatingCrew={this.state.modifiedCrew}
          updatingOrganization={this.state.modifiedOrganization} />
      </main>
    )
 }
}

export default Users;