import React from 'react';
import '../shared/common.scss';
import EventModal from './EventModal.js';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import Pagination from 'react-js-pagination';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper';


class Events extends React.Component {
  _isMounted = false;
    state = {
        data: [],
        pageSize:10,
        activePage:1,
        sliceFrom:0,
        rowSelected:false,
        selectedRowId:null,
        selectedEvent:null,
        modalTitle:'',
        editMode: false,
      };  

    componentDidMount() {
      this._isMounted = true;
        store.dispatch(actions.event.getAllEvents());
        store.subscribe(() => {
          if (this._isMounted) {
            this.setState({
            data:store.getState().event.filteredData,
            rowSelected:false
             })
            }
        });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({selectedEvent:null, editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
          store.dispatch(actions.event.openCloseModal());
      }

    clickRow(id) {
      this.openModal('Editing existing Event', true)
        
        this.setState(prevState => {

            const selectedEvent = prevState.data.filter(event =>
                event._id === id
            )[0]
            return { selectedEvent };
        })
    }

    renderEventList = () => this.state.data?.slice(this.state.sliceFrom,this.state.sliceFrom + this.state.pageSize).map((event, index) => (
      <tr id={event._id} key={index} onClick={() => this.clickRow(event._id)} className={"data-row"}>
          <td>{event.name}</td>
          <td>{event.lead}</td>
          <td>{event.status}</td>
          <td>{event.type}</td>
          <td>{event.subscription}</td>
          <td><ul>{this.iterateUsers(event.users)}</ul></td>
      </tr>
    )); 

    iterateUsers = (users) =>  users?.map((user) => (
        <li>{user.email}</li>
    ))


    handlePageChange(pageNumber) {
        this.setState(prevState => {
            return {
                ...prevState,
                activePage: pageNumber,
                sliceFrom: (pageNumber * prevState.pageSize) - prevState.pageSize
            }

        });
    }

    removeEvent() {
        store.dispatch(actions.event.removeEvent(this.props.id));
    }

    search(e) {
        this.handlePageChange(1);
        let value = '';
        value += e.target.value;
        store.dispatch(actions.event.searchEvent(value));
      }

    render() {
    return (
        <main className='main-content bgc-grey-100' id="root">
        <div id='mainContent'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new Event', false)}>Add</button>
                </div>
                <input type="search" placeholder="Search..." onInput={(e) => this.search(e) }></input>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Lead</th>
                          <th>Status</th>
                          <th>Type</th>
                          <th>Subscription</th>
                          <th>Users</th>
                        </tr>
                      </thead>
                      <tbody>
                      {store.getState().event.loading ?
                        <tr><td colSpan="9" className="align-to-center"> <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        </td>
                        </tr>
                        :
                        this.renderEventList()}
                    </tbody>
                    </table>
                    <div className="table-footer">
                    <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing {this.state.activePage} to {this.state.pageSize} of {this.state.data?.length} entries</div>
                    <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
          activePage={this.state.activePage}
          itemsCountPerPage={IsNullOrEmptyOrUndefined(this.state.pageSize)? 0 : this.state.pageSize}
          totalItemsCount={IsNullOrEmptyOrUndefined(this.state.data?.length)? 0 : this.state.data?.length}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EventModal 
      event={this.state.selectedEvent}
      modalTitle={this.state.modalTitle}
      editMode={this.state.editMode} />
      </main>
    )
 }
}

export default Events;
