import React from 'react';
import { Link } from 'react-router-dom';
import '../shared/common.scss';

function Home() {
    return (
        <div>

            <div className="tiles-container">
                <Link to="/Users">
                    <div className="card text-white bg-dark mb-3 tile brown">
                        <div className="card-header brown">Users</div>
                        <div className="card-body brown">
                            <span className="icon-holder center-v-h">
                                <i className="c-white-500 ti-user icon"></i>
                            </span>
                        </div>
                    </div>
                </Link>
                <Link to="/Posts">
                    <div className="card text-white bg-secondarybg-dark mb-3 tile blue">
                        <div className="card-header blue">Posts</div>
                        <div className="card-body blue">
                            <span className="icon-holder center-v-h">
                                <i className="c-white-500 ti-receipt icon"></i>
                            </span>
                        </div>
                    </div>
                </Link>
                <Link to="/Campings">
                    <div className="card text-white bg-dark mb-3 tile orange">
                        <div className="card-header orange">Campings</div>
                        <div className="card-body orange">
                            <span className="icon-holder center-v-h">
                                <i className="c-white-500 ti-location-arrow icon"></i>
                            </span>
                        </div>
                    </div>
                </Link>
                <Link to="/Crew">
                    <div className="card text-white bg-dark mb-3 tile purple">
                        <div className="card-header purple">Teams</div>
                        <div className="card-body purple">
                            <span className="icon-holder center-v-h">
                                <i className="c-white-500 ti-car icon"></i>
                            </span>
                        </div>
                    </div>
                </Link>
                <Link to="/Events">
                    <div className="card text-white bg-dark mb-3 tile indigo">
                        <div className="card-header indigo">Events</div>
                        <div className="card-body indigo">
                            <span className="icon-holder center-v-h">
                                <i className="c-white-500 ti-calendar icon"></i>
                            </span>
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    )
}

export default Home;