import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import FilteredMultiSelect from 'react-filtered-multiselect'
import '../shared/common.scss'
import { BOOTSTRAP_CLASSES } from '../../utils/formatters/cmponentsFormatter.js';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import ConfirmationModal from '../shared/ConfirmationModal.js';

Modal.setAppElement('#root')

class EventModal extends React.Component {


    constructor() {
        super();
        this.state = {
            Id:null,
            inputName:'',
            inputDesc :'',
            inputStatus:'',
            inputType:'',
            inputSubscription:'',
            selectedOptions:[] ,
          }
    }

    componentDidMount() {
        store.dispatch(actions.user.getAllUsers());
        store.subscribe(() => {
            this.setState({
            users: this.proccessData(store.getState().user?.data),
             })
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            Id:nextProps.event?._id,
            inputName:nextProps.event?.name,
            inputDesc:nextProps.event?.desc,
            inputLead:nextProps.event?.lead,
            inputStatus:nextProps.event?.status,
            inputType:nextProps.event?.type,
            inputSubscription:nextProps.event?.subscription,
            selectedOptions: IsNullOrEmptyOrUndefined(nextProps.event?.users)? [] : nextProps.event?.users
        })
      }

    proccessData(data) {
        const userObjects = data?.map(user => {
           return  {_id:user._id, email:user.email}
        }).filter(item => !IsNullOrEmptyOrUndefined(item.email))

        return userObjects;
    }

    handleSubmit = (event) => {
        
        event.preventDefault();
        const EventObject = {
            _id: this.state.Id,
            name:this.state.inputName,
            desc: this.state.inputDesc,
            lead: this.state.inputLead,
            status: this.state.inputStatus,
            type: this.state.inputType,
            subscription: this.state.inputSubscription,
            users: this.state.selectedOptions
        }
       this.props.editMode? store.dispatch(actions.event.editEvent(EventObject)) : store.dispatch(actions.event.addEvent(EventObject));
    }

    closeModal() {
        store.dispatch(actions.event.actionError(null));
        store.dispatch(actions.event.openCloseModal());
      }

      openConfirmationModal() {
        store.dispatch(actions.modal.openCloseConfirmationModal('Removing event'));
    }

      handleDeselect = (deselectedOptions) => {
        var selectedOptions = this.state.selectedOptions.slice()
        deselectedOptions.forEach(option => {
          selectedOptions.splice(selectedOptions.indexOf(option), 1)
        })
        this.setState({selectedOptions})
      }
      handleSelect = (selectedOptions) => {
        selectedOptions.sort((a, b) => a.id - b.id)
        this.setState({selectedOptions})
      }

    onChange = e => this.setState({ [e.target.id]: e.target.value })
    
    render() {
        const { users, selectedOptions } = this.state;

    return (
       <div>
        <Modal
          isOpen={store.getState().event.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.props.modalTitle}</h6>
                    <div className="mT-30">
                        <form onSubmit={this.handleSubmit}>
                        <Tabs>
    <Tab label="General inforation" className="tab">
                            <div className="form-group">
                                <label htmlFor="inputName">Name</label>
                                <input type="name" className="form-control" id="inputName" required aria-describedby="nameHelp" placeholder="Enter name" defaultValue={this.state.inputName}  onChange={this.onChange}  ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputLead">Lead</label>
                                <input type="text" className="form-control" id="inputLead" required placeholder="Enter Lead" defaultValue={this.state.inputLead}  onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputDesc">Description</label>
                                <input type="text" className="form-control" id="inputDesc" placeholder="Enter Description" defaultValue={this.state.inputDesc}  onChange={this.onChange}></input>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputStatus">Status</label>
                                <select id="inputStatus" className="form-control" defaultValue={this.state.inputStatus}  onChange={this.onChange}>
                                    <option defaultValue>draft</option>
                                    <option>pending</option>
                                    <option>open</option>
                                    <option>ongoing</option>
                                    <option>completed</option>
                                    <option>cancelled</option>
                                    <option>deleted</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputType">Type</label>
                                <select id="inputType" className="form-control" defaultValue={this.state.inputType} onChange={this.onChange}>
                                    <option defaultValue>public</option>
                                    <option>restricted</option>
                                    <option>private</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputSubscription">Subscription</label>
                                <select id="inputSubscription" className="form-control" defaultValue={this.state.inputSubscription} onChange={this.onChange}>
                                    <option defaultValue>freetier</option>
                                    <option>standard</option>
                                    <option>premium</option>
                                </select>
                            </div>
                            </div>
                            </Tab>
                            <Tab label="Users" className="tab">
      <div className="form-group col-md-12">
      <label htmlFor="inputUsers">Users</label>
      <div className="control-buttons">
        <FilteredMultiSelect
          showFilter = {true}
          buttonText="Add"
          classNames={BOOTSTRAP_CLASSES}
          onChange={this.handleSelect}
          options={ users }
          selectedOptions={selectedOptions}
          textProp="email"
          valueProp="_id"
        />
        <FilteredMultiSelect
          id="inputUsers"
          buttonText="Remove"
          classNames={BOOTSTRAP_CLASSES}
          onChange={this.handleDeselect}
          options={selectedOptions}
          textProp="email"
          valueProp="_id"
        />
        </div>
    </div>
      </Tab>
      </Tabs>
      {!IsNullOrEmptyOrUndefined(store.getState().event.actionError)?
<div className="alert alert-danger align-to-center max-width-600" role="alert">
<span>{store.getState().event.actionError}</span>
                    </div> : ''}
                    <div className={this.props.editMode? "control-buttons" : "control-buttons justify-to-right"}>
                        <button type="button" className={this.props.editMode? "btn btn-danger" : "hidden" }  onClick={this.openConfirmationModal}>Remove</button>
                        <span className="control-buttons">
                        <button type="button" className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </span>
                    </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <ConfirmationModal 
      id={this.state.Id} 
      action={actions.event.removeEvent(this.state.Id)} 
      field={this.state.inputName}
      title='Removing event' message={'If you are sure you want to delete this event, enter the name of event you want to delete:'} 
       />
        </div>
    )
 }
}

export default EventModal;