export const GET_ALL_POSTS = 'camping/GET_ALL_POSTS';
export const SET_ALL_POSTS = 'camping/SET_ALL_POSTS';
export const RESET_POST = 'camping/RESET_POST';
export const ADD_POST = 'camping/ADD_POST';
export const REMOVE_POST = 'camping/REMOVE_POST';
export const EDIT_POST = 'camping/EDIT_POST';
export const SEARCH_POST = 'camping/SEARCH_POST';
export const POST_UPDATED_SUCCESS = 'camping/POST_UPDATED_SUCCESS';
export const POST_ERROR = 'camping/POST_ERROR';
export const OPEN_CLOSE_MODAL = 'camping/OPEN_CLOSE_MODAL';

export const getAllPosts = () => ({
    type:GET_ALL_POSTS
})

export const setAllPosts = (payload) => ({
    type:SET_ALL_POSTS,
    payload
})

export const addPost = (payload) => ({
    type:ADD_POST,
    payload
})

export const editPost = (payload) => ({
    type:EDIT_POST,
    payload
})

export const removePost = (payload) => ({
    type:REMOVE_POST,
    payload
})

export const searchPost = (payload) => ({
    type:SEARCH_POST,
    payload
})

export const postUpdatedSuccess = () => ({
    type: POST_UPDATED_SUCCESS,
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const postError = (payload) => ({
    type: POST_ERROR,
    payload
});