export const INIT = "auth/INIT";
export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_ERROR = "auth/LOGIN_ERROR";
export const LOGOUT = "auth/LOGOUT";

export const init = () => ({
    type: INIT
});

export const login = (payload) => ({
    type: LOGIN,
    payload
});

export const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload
});

export const loginError = (payload) => ({
    type: LOGIN_ERROR,
    payload
});

export const logout = () => ({
    type: LOGOUT
});

