import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

const getAllEventsEpic = (action$) =>
  action$.pipe(
    ofType(actions.event.GET_ALL_EVENTS),
    mergeMap(() => {
      return AxiosRallyGoAPI.events.getAllEvents()
        .then(response => actions.event.setAllEvents(response.data))
        .catch(error => actions.event.eventError(error.response.data.error));
    }),
  );

  const addEventsEpic = (action$) =>
  action$.pipe(
    ofType(actions.event.ADD_EVENT),
    mergeMap(action => {
      return AxiosRallyGoAPI.events.addEvent(action.payload)
        .then(response => actions.event.eventAddedSuccess({...response.data, users: action.payload.users}))
        .catch(error => actions.event.actionError(error.response.data.error));
    }),
  );

  const editEventsEpic = (action$) =>
  action$.pipe(
    ofType(actions.event.EDIT_EVENT),
    mergeMap(action => {
      return AxiosRallyGoAPI.events.editEvent(action.payload)
        .then(response => actions.event.eventUpdatedSuccess(action.payload))
        .catch(error => actions.event.eventError(error.response.data.error));
    }),
  );

  const removeEventsEpic = (action$) =>
  action$.pipe(
    ofType(actions.event.REMOVE_EVENT),
    mergeMap(action => {
      return AxiosRallyGoAPI.events.removeEvent(action.payload)
        .then(response => actions.event.eventRemovedSuccess(response.data._id))
        .catch(error => actions.event.eventError(error.response.data.error));
    }),
  );

  export default combineEpics(getAllEventsEpic, addEventsEpic, removeEventsEpic, editEventsEpic);