import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import { user } from './user/userReducer.js';
import { modal } from './modal/modalReducer.js';
import { camping } from './camping/campingReducer.js';
import { event } from './event/eventReducer.js';
import { crew } from './crew/crewReducer.js';
import { organization } from './organization/organizationReducer.js';
import { post } from './post/postReducer.js';
import { auth } from './auth/authReducer.js'

import userEpics from './user/userEpics';
import campingEpics from './camping/campingEpics.js';
import eventEpics from './event/eventEpics.js';
import crewEpics from './crew/crewEpics.js';
import organizationEpics from './organization/organizationEpics.js';
import postEpics from './post/postEpics.js';
import authEpics from './auth/authEpics.js';

const appReducer = combineReducers({
    user,
    modal,
    camping,
    event,
    crew,
    organization,
    post,
    auth,
});
const rootReducer = (state, action) => appReducer(state, action);

const rootEpic = combineEpics(
    userEpics,
    campingEpics,
    eventEpics,
    crewEpics,
    organizationEpics,
    postEpics,
    authEpics,
);

const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(epicMiddleware)
  ));

epicMiddleware.run(rootEpic);

export default store;