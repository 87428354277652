import { IsNullOrEmptyOrUndefined, setErrorMessage } from '../../utils/helpers/validatorHelper.js';
import { actions } from '../actions.js';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    loading: true,
    error: null,
    actionError: null,
    filteredData: null,
    data: null,
    userRoles: null,
    userOrganizations: null,
    userCrew:null,
    modalIsOpen: false,
    selectedUser: {
        _id: null,
        email: '',
        fname: '',
        lname: '',
        passwd: '',
        status: '',
    }
}

export const user = (state, action) => {
    switch (action.type) {
        case actions.user.GET_ALL_USERS:
            return {
                ...state,
                filteredData: [],
                data: [],
                userRoles: [],
                userOrganizations: null,
                userCrew:null,
                selectedUser: {},
                loading: true,
                modalIsOpen: false,
                error: null,
                actionError: null
            };
        case actions.user.SET_ALL_USERS:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: null,
                actionError: null,
            }
        case actions.user.SET_USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            }
        case actions.user.SET_USER_CREW:
            return {
                ...state,
                userCrew: action.payload
                }
        case actions.user.SET_USER_ORGANIZATIONS:
            return {
                ...state,
                userOrganizations: action.payload
            }
        case actions.user.SET_USER:
            return {
                ...state,
                selectedUser: action.payload,
            }
        case actions.user.SEARCH_USER:
            return {
                ...state,
                filteredData: action.payload.length > 2 ? state.data.filter((item) =>
                    item.email?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.fname?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.lname?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.status?.toLowerCase().includes(action.payload.toLowerCase())
                ) : [...state.data]
            }
        case actions.user.USER_ADDED_SUCCESS:
            toast.success("User added with success!");
            return {
                ...state,
                data: [...state.data, action.payload],
                filteredData: [...state.data, action.payload],
                modalIsOpen: false,
            }
        case actions.user.USER_UPDATED_SUCCESS:
            toast.success("User updated with success!");
            return {
                ...state,
                data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                filteredData: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                modalIsOpen: false,
            }
        case actions.user.USER_REMOVED_SUCCESS:
            toast.success("User removed with success!");
            return {
                ...state,
                data: state.data.filter((item) => item._id !== action.payload),
                filteredData: state.data.filter((item) => item._id !== action.payload),
                modalIsOpen: false,
            }
        case actions.user.OPEN_CLOSE_MODAL:
            return {
                ...state,
                modalIsOpen: !state.modalIsOpen,
            };
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
            };
        case actions.user.USER_ERROR:
            toast.error("Internal Server Error: "+IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, true));
            return {
                ...state,
                loading: false,
                error: IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, true)
            };
        case actions.user.ACTION_ERROR:
           if(!IsNullOrEmptyOrUndefined(action.payload?.data?.name)) toast.error("Validation Internal Error: " + action.payload?.data?.name);
            return {
                ...state,
                loading: false,
                actionError: IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, true)
            };
        default:
            return {
                ...state
            }

    }
}