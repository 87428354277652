import { toast } from 'react-toastify';
import { IsNullOrEmptyOrUndefined, setErrorMessage } from '../../utils/helpers/validatorHelper.js';
import { actions } from '../actions.js';

const INITIAL_STATE = {
    loading: true,
    error: null,
    actionError: null,
    filteredData: null,
    data: null,
    modalIsOpen: false,
};

export const event = (state, action) => {
    switch (action.type) {
        case actions.event.GET_ALL_EVENTS:
            return {
                ...state,
                filteredData: [],
                data: [],
                loading: true,
                error: null,
                actionError: null,
                modalIsOpen: false,
            };
        case actions.event.SET_ALL_EVENTS:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: null,
                actionError:null,
            }
        case actions.event.SEARCH_EVENT:
            return {
                ...state,
                filteredData: action.payload.length > 2 ? state.data.filter((item) =>
                    item.name?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.desc?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.lead?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.status?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.type?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.subscription?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.user?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.users.some(t => t.fname?.toLowerCase().includes(action.payload.toLowerCase())
                        || t.lname?.toLowerCase().includes(action.payload.toLowerCase())
                        || t.email?.toLowerCase().includes(action.payload.toLowerCase())
                    )
                ) : [...state.data]
            }
        case actions.event.EVENT_ADDED_SUCCESS:
            toast.success("Event added with success!");
            return {
                ...state,
                data: [...state.data, action.payload],
                filteredData: [...state.data, action.payload],
                modalIsOpen: false,
            }
        case actions.event.EVENT_UPDATED_SUCCESS:
            toast.success("Event updated with success!");
            return {
                ...state,
                data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                filteredData: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                modalIsOpen: false,
            }
        case actions.event.EVENT_REMOVED_SUCCESS:
            toast.success("Event removed with success!");
            return {
                ...state,
                data: state.data.filter((item) => item._id !== action.payload),
                filteredData: state.data.filter((item) => item._id !== action.payload),
                modalIsOpen: false,
            }
        case actions.event.OPEN_CLOSE_MODAL:
            return {
                ...state,
                modalIsOpen: !state.modalIsOpen,
            };
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
                data: [],
                filteredData: [],
            };
        case actions.event.EVENT_ERROR:
            toast.error("Internal Server Error: "+IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, false));
            return {
                ...state,
                loading: false,
                error: IsNullOrEmptyOrUndefined(action.payload)? null : setErrorMessage(action.payload, false)
            };
        case actions.event.ACTION_ERROR:
            if(!IsNullOrEmptyOrUndefined(action.payload)) toast.error(setErrorMessage(action.payload, false));
                return {
                    ...state,
                    loading: false,
                    actionError: IsNullOrEmptyOrUndefined(action.payload)? null : setErrorMessage(action.payload, false)
                    };
        default:
            return {
                ...state
            }

    }
}