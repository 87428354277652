import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

  const loginEpic = (action$) =>
  action$.pipe(
    ofType(actions.auth.LOGIN),
    mergeMap(action => {
      return AxiosRallyGoAPI.auth.login(action.payload)
        .then(response => actions.auth.loginSuccess(response.data))
        .catch(error => actions.auth.loginError(error.message));
    }),
  );

  
  export default combineEpics(loginEpic);