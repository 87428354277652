import React from 'react';
import '../shared/common.scss';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';


class Login extends React.Component {

    state = {
      };  

    constructor() {
        super();    
        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
    }

    handleSubmit = (event) => {

        event.preventDefault();

        const loginObject = {
            email:this.inputEmail.current.value,
            passwd: this.inputPassword.current.value,
        }

        store.dispatch(actions.auth.login(loginObject));
    }

    render() {
    return (
<div className="container center">
	<div className="row text-center">
	    <div className="col-md-6 offset-md-3">
	        <div className="card">
	            <div className="card-body">
	                <div className="login-title">
                        <img src="logo_rallygo.png" alt="RallyGO" className="login-logo" width="300"/>
	                </div>
	                <div className="login-form mt-4">
	                    <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                              <input id="inputEmail"  placeholder="Email Address" className="form-control" type="text"  ref={this.inputEmail} />
                            </div>
                            <div className="form-group col-md-12">
                              <input id="inputPassword" className="form-control" placeholder="Password"  type="password"  ref={this.inputPassword} />
                            </div>
                          </div>
                          {this.props.previousLoginAttemptFailed?
                          <div className="alert alert-danger" role="alert">
                      {this.props.errorMessage}
                    </div> : ''        
                    }
                        <div className="form-row">
                            <button type="submit" className="btn btn-danger btn-block">
                             {store.getState().auth.loading ?<div>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="sr-only">Loading...</span></div> :
                            <span>Log in</span>
                             }
                        </button>
                        </div>
                    </form>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</div>
    )
 }
}

export default Login;
