import React from 'react';
import '../shared/common.scss';
import CrewModal from './CrewModal.js';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import Pagination from 'react-js-pagination';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper';


class Crew extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      data: [],
      pageSize:25,
      activePage:1,
      sliceFrom:0,
      rowSelected:false,
      selectedRowId:null,
      selectedCrew:null,
      modalTitle:'',
      editMode: false,
      users:'',
    }; 
  }
 

    componentDidMount() {
      this._isMounted = true;
        store.dispatch(actions.user.getAllUsers());
        store.dispatch(actions.crew.getAllCrew());
        store.subscribe(() => {
          if (this._isMounted) {
            this.setState({
            users:store.getState().user.data,
            data:store.getState().crew.filteredData,
            rowSelected:false
             })
            }
        });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({selectedCrew:null, editMode:false});
        } else {
            store.dispatch(actions.crew.setCrew(this.state.selectedCrew));
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
          store.dispatch(actions.crew.openCloseModal());
      }

    clickRow(id) {       
      this.openModal('Editing existing team', true);
      
     const crew ={...this.state.data?.filter(crew => crew._id === id)[0]}

        this.setState(prevState => {

            const selectedCrew = prevState.data?.filter(crew =>
                crew._id === id
            )[0]
            return { selectedCrew };
        })

        store.dispatch(actions.crew.setCrew(crew));
    }

    renderCrewList = () => this.state.data?.slice(this.state.sliceFrom,this.state.sliceFrom + this.state.pageSize).map((crew) => (
      <tr id={crew._id} key={crew._id} onClick={() => this.clickRow(crew._id)} className="data-row">
          <td>{crew.name}</td>
          <td>{crew.desc}</td>
          <td>{crew.avatar}</td>
          <td>{crew.city}</td>
          <td>{crew.country}</td>
          <td><ul>{this.iterateUsers(crew.users)}</ul></td>
          <td><ul>{this.iterateMembers(crew.members)}</ul></td>
          <td><ul>{this.iterateContacts(crew.contact)}</ul></td>
      </tr>
    )); 

    iterateUsers = (users) =>  users?.map((user, index) => (
        <li key={index}>{`${this.setUserName(user.user)} (${user.role})`}</li>
    ))

    iterateMembers = (members) =>  members?.map((member, index) => (
      <li key={index}>{`${member.fname} ${member.lname} (${member.pos})`}</li>
  ))

  iterateContacts = (contacts) =>  contacts?.map((contact, index) => (
    <li key={index}>{`${contact.type}: ${contact.value}`}</li>
))

    setUserName = (id) => this.state.users?.filter(user => user._id === id)[0]?.email


    handlePageChange(pageNumber) {
        this.setState(prevState => {
            return {
                ...prevState,
                activePage: pageNumber,
                sliceFrom: (pageNumber * prevState.pageSize) - prevState.pageSize
            }

        });
    }

    removeCrew() {
        store.dispatch(actions.crew.removeCrew(this.props.id));
    }

    search(e) {
        this.handlePageChange(1);
        let value = '';
        value += e.target.value;
        store.dispatch(actions.crew.searchCrew(value));
      }

    render() {
    return (
        <main className='main-content bgc-grey-100' id="root">
        <div id='mainContent'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new team', false)}>Add</button>
                </div>
                <input type="search" placeholder="Search..." onInput={(e) => this.search(e) }></input>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Avatar</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Users</th>
                          <th>Members</th>
                          <th>Contacts</th>
                        </tr>
                      </thead>
                      <tbody>
                      {store.getState().crew.loading ?
                        <tr><td colSpan="9" className="align-to-center"> <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        </td>
                        </tr>
                        :
                        this.renderCrewList()}
                    </tbody>
                    </table>
                    <div className="table-footer">
                    <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing {this.state.activePage} to {this.state.pageSize} of {this.state.data?.length} entries</div>
                    <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
          activePage={this.state.activePage}
          itemsCountPerPage={IsNullOrEmptyOrUndefined(this.state.pageSize)? 0 : this.state.pageSize}
          totalItemsCount={IsNullOrEmptyOrUndefined(this.state.data?.length)? 0 : this.state.data?.length}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CrewModal 
      users={this.state.users}  
      crew={this.state.selectedCrew}
      modalTitle={this.state.modalTitle}
      editMode={this.state.editMode} />
      </main>
    )
 }
}

export default Crew;