import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import { makeid} from '../../utils/helpers/dataHelper.js';


Modal.setAppElement('#root')

class CrewContacts extends React.Component {


    constructor() {
        super();
        this.state = {
          selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
          crewContact:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.contact)? [] : store.getState().crew.selectedCrew?.contact,
          modalIsOpen:false,
          editMode:false,
          modalTitle:'',
          selectedContact:{},
          inputType:'tel',
          inputValue:'',
          pos:'',
        }


    }

    componentDidMount() {
      store.subscribe(() => {
          this.setState({
            selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
            crewContact:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.contact)? [] : store.getState().crew.selectedCrew?.contact,
           })
      });
  }

      showContact(id) {
          this.setState(prevState => {
            const selectedContact = prevState.crewContact.filter(contact =>
                contact._id === id
            )[0]

            return { selectedContact: selectedContact };
        })

        this.openModal('Editing exisitng contact', true)
      }

      openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
         this.setState({
             modalIsOpen:true
         })
      }

      closeModal() {
        this.setState({
            modalIsOpen:false,
            selectedContact:{}
        })
      }

      addContact() {
        var contacts = this.state.crewContact;
        contacts.push({_id:makeid(), type:this.state.inputType, value:this.state.inputValue});
        this.setState({crewContact:contacts});

        let modifingContact = this.state.selectedCrew;
        modifingContact.contact = contacts;
        

        store.dispatch(actions.crew.setCrew(modifingContact));
        this.closeModal();
      }

      removeContact() {
          
         const contact = this.state.crewContact.filter(contact => contact._id !== this.state.selectedContact._id);
         this.setState({crewContact: contact});

         let modifingContact = this.state.selectedCrew;
         modifingContact.contact = contact;

         store.dispatch(actions.crew.setCrew(modifingContact));
         this.closeModal();
      }

      rendercontactList = () => this.state.crewContact.map((contact) => (
        <tr id={contact?._id} key={contact?._id} onClick={() => this.showContact(contact?._id)}>
            <td>{contact?.type}</td>
            <td>{contact?.value}</td>
        </tr>
      )); 


      onChange = e => this.setState({ [e.target.id]: e.target.value })

    render() {
    return (
<div>

<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.state.modalTitle}</h6>
                    <div className="mT-30">
                    <div className="form-group">
                                <label htmlFor="inputType">Type</label>
                                <select id="inputType" className="form-control" disabled={this.state.editMode}  defaultValue={this.state.selectedContact?.type} onChange={this.onChange}>
                                    <option>tel</option>
                                    <option>email</option>
                                    <option>fb</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputValue">Value</label>
                                <input type="name" className="form-control" id="inputValue" aria-describedby="ValueHelp" placeholder="Enter Value" defaultValue={this.state.selectedContact?.type} disabled={this.state.editMode} onChange={this.onChange} ></input>
                            </div>

                            <div className={this.state.editMode? "control-buttons" : "control-buttons justify-to-right"} >
                            <button className={this.state.editMode? "btn btn-danger" : "hidden" } onClick={() => this.removeContact()}>Remove</button>
                              <span className="control-buttons"> 
                               <button type="button" className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                               <button type="button" className={!this.state.editMode? "btn btn-primary" : "hidden" } onClick={() => this.addContact()}>Add</button>
                              </span>
                            </div>
                    </div>
                </div>
            </Modal>

    <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new contact', false)}>Add Contact</button>
                </div>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                    {this.rendercontactList()}
                      </tbody>
                    </table>
               </div>
              </div>
            </div>


    </div>
    </div>
    )
 }
}

export default CrewContacts;