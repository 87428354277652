import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

const getAllCrewEpic = (action$) =>
  action$.pipe(
    ofType(actions.crew.GET_ALL_CREW),
    mergeMap(() => {
      return AxiosRallyGoAPI.crew.getAllCrew()
        .then(response => actions.crew.setAllCrew(response.data))
        .catch(error => actions.crew.crewError(error.response?.data?.details?.message));
    }),
  );

  const addCrewEpic = (action$) =>
  action$.pipe(
    ofType(actions.crew.ADD_CREW),
    mergeMap(action => {
      return AxiosRallyGoAPI.crew.addCrew(action.payload)
        .then(response => actions.crew.crewAddedSuccess(response.data))
        .catch(error => actions.crew.actionError(error.response?.data?.details?.message));
    }),
  );

  const editCrewEpic = (action$) =>
  action$.pipe(
    ofType(actions.crew.EDIT_CREW),
    mergeMap(action => {
      return AxiosRallyGoAPI.crew.editCrew(action.payload)
        .then(response => actions.crew.crewUpdatedSuccess(response.data))
        .catch(error => actions.crew.actionError(error.response?.data?.details?.message));
    }),
  );

  const removeCrewEpic = (action$) =>
  action$.pipe(
    ofType(actions.crew.REMOVE_CREW),
    mergeMap(action => {
      return AxiosRallyGoAPI.crew.removeCrew(action.payload)
        .then(response => actions.crew.crewRemovedSuccess(response.data._id))
        .catch(error => actions.crew.actionError(error.response?.data?.details?.message));
    }),
  );

  export default combineEpics(getAllCrewEpic, addCrewEpic, removeCrewEpic, editCrewEpic);