import React from 'react';
import { Link} from 'react-router-dom';

function Sidebar() {
    return (
  
<div className="sidebar">
<div className="sidebar-inner">
  {/* ### $Sidebar Header ### */}
  <div className="sidebar-logo">
    <div className="peers ai-c fxw-nw">
      <div className="peer peer-greed">
        <a className="sidebar-link td-n" href="/">
          <div className="peers ai-c fxw-nw">
            <div className="peer">
              <div className="logo">
                <img src="assets/static/images/logo.png" alt=""></img>
              </div>
            </div>
            <div className="peer peer-greed">
                <img src="logo_rallygo.png" width="100" />
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>

  {/* ### $Sidebar Menu ### */}
  <ul className="sidebar-menu scrollable pos-r">
    <li className="nav-item mT-30 actived">
    <Link className='sidebar-link' to="/">
        <span className="icon-holder">
          <i className="c-blue-500 ti-home"></i>
        </span>
        <span className="title">Home</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link className='sidebar-link' to="/users">
        <span className="icon-holder">
          <i className="c-brown-500 ti-user"></i>
        </span>
        <span className="title">Users</span>
      </Link>
    </li>
    <li className="nav-item">
    <Link className='sidebar-link' to="/posts">
        <span className="icon-holder">
          <i className="c-blue-500 ti-receipt"></i>
        </span>
        <span className="title">Posts</span>
      </Link>
    </li>
    <li className="nav-item">
    <Link className='sidebar-link' to="/campings">
        <span className="icon-holder">
          <i className="c-deep-orange-500 ti-location-arrow"></i>
        </span>
        <span className="title">Campings</span>
      </Link>
    </li>
    <li className="nav-item">
    <Link className='sidebar-link' to="/teams">
        <span className="icon-holder">
          <i className="c-deep-purple-500 ti-car"></i>
        </span>
        <span className="title">Teams</span>
      </Link>
    </li>
    <li className="nav-item">
    <Link className='sidebar-link' to="/events">
        <span className="icon-holder">
          <i className="c-indigo-500 ti-calendar"></i>
        </span>
        <span className="title">Events</span>
      </Link>
    </li>
    </ul>
</div>
</div>

    );}

export default Sidebar
