import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import { makeid} from '../../utils/helpers/dataHelper.js';


Modal.setAppElement('#root')

class crewMembers extends React.Component {


    constructor() {
        super();
        this.state = {
          selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
          crewMembers:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.members)? [] : store.getState().crew.selectedCrew?.members,
          modalIsOpen:false,
          editMode:false,
          modalTitle:'',
          selectedMember:{},
          id:'',
          inputFirstName:'',
          inputLastName:'',
          inputPosition:''
        }


    }

    componentDidMount() {
      store.subscribe(() => {
          this.setState({
            selectedCrew:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew)? [] : store.getState().crew.selectedCrew,
            crewMembers:IsNullOrEmptyOrUndefined(store.getState().crew.selectedCrew?.members)? [] : store.getState().crew.selectedCrew?.members,
           })
      });
  }


      showMember(id) {
          this.setState(prevState => {
            const selectedMember = prevState.crewMembers.filter(member =>
                member._id === id
            )[0]

            return { selectedMember };
        })

        this.openModal('Editing exisitng member', true)
      }

      openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
         this.setState({
             modalIsOpen:true
         })
      }

      closeModal() {
        this.setState({
            modalIsOpen:false,
            selectedMember:{}
        })
      }

      addMember() {

        var members = this.state.crewMembers;
        members.push({_id:makeid(), fname:this.state.inputFirstName, lname:this.state.inputLastName, pos:this.state.inputPosition});
        this.setState({crewMembers: members});

        let modifingMember = this.state.selectedCrew;
        modifingMember.members = members;

        store.dispatch(actions.crew.setCrew(modifingMember));
        this.closeModal();
      }

      removeMember() {
          
         const members = this.state.crewMembers.filter(member => member._id !== this.state.selectedMember._id);
         this.setState({crewMembers: members});

         let modifingMember = this.state.selectedCrew;
         modifingMember.members = members;

         store.dispatch(actions.crew.setCrew(modifingMember));
         this.closeModal();
      }

      renderMemberList = () => this.state.crewMembers.map((member) => (
        <tr id={member?._id} key={member?._id} onClick={() => this.showMember(member?._id)}>
            <td>{member?.fname}</td>
            <td>{member?.lname}</td>
            <td>{member?.pos}</td>
        </tr>
      )); 


      onChange = e => this.setState({ [e.target.id]: e.target.value })

    render() {
    return (
<div>

<Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.state.modalTitle}</h6>
                    <div className="mT-30">
                        <div className="form-group">
                        <label htmlFor="inpuFirstName">First Name</label>
                                <input type="name" className="form-control" id="inputFirstName" aria-describedby="firstNameHelp" placeholder="Enter First Name" disabled={this.state.editMode}  defaultValue={this.state.selectedMember.fname} onChange={this.onChange} ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputLastName">Last Name</label>
                                <input type="name" className="form-control" id="inputLastName" aria-describedby="LastNameHelp" placeholder="Enter Last Name" disabled={this.state.editMode}  defaultValue={this.state.selectedMember.lname} onChange={this.onChange} ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputPosition">Position</label>
                                <input type="text" className="form-control" id="inputPosition" placeholder="Enter Position" disabled={this.state.editMode} defaultValue={this.state.selectedMember?.pos} onChange={this.onChange} ></input>
                            </div>

                            <div className={this.state.editMode? "control-buttons" : "control-buttons justify-to-right"} >
                            <button className={this.state.editMode? "btn btn-danger" : "hidden" } onClick={() => this.removeMember()}>Remove</button>
                              <span className="control-buttons"> 
                               <button className="btn btn-primary" type="button" onClick={() => this.closeModal()}>Cancel</button>
                               <button type="button" className={!this.state.editMode? "btn btn-primary" : "hidden" } onClick={() => this.addMember()}>Add</button>
                              </span>
                            </div>
                    </div>
                </div>
            </Modal>

    <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new member', false)}>Add Member</button>
                </div>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Position</th>
                        </tr>
                      </thead>
                      <tbody>
                    {this.renderMemberList()}
                      </tbody>
                    </table>
               </div>
              </div>
            </div>


    </div>
    </div>
    )
 }
}

export default crewMembers;