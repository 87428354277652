import { actions } from '../actions.js';


const INITIAL_STATE = {
    isOpen: false,
    confirmationModalIsOpen: false,
}

export const modal = (state, action) => {
    switch (action.type) {
        case actions.modal.OPEN_CLOSE_MODAL:
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        case actions.modal.OPEN_CLOSE_CONFIRMATION_MODAL:
            return {
                ...state,
                confirmationModalIsOpen: !state.confirmationModalIsOpen,
            };
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
                isOpen: false,
                confirmationModalIsOpen: false,
            };
        default:
            return {
                ...state
            }
    }
}