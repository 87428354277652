import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';


Modal.setAppElement('#root')

class UserCrew extends React.Component {

    constructor(props) {
        super();
        this.state = {
            userCrew:IsNullOrEmptyOrUndefined(props.data)? [] : props.data,
            crew:IsNullOrEmptyOrUndefined(props.crew)? [] : props.crew,
            updatingCrew:{},
            modalIsOpen:false,
            editMode:false,
            modalTitle:'',
            selectedCrew:{},
          }
          this.inputCrew = React.createRef();
          this.inputRole = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userCrew: IsNullOrEmptyOrUndefined(nextProps.data)? [] : nextProps.data,
            crew: IsNullOrEmptyOrUndefined(nextProps.crew)? [] : nextProps.crew,
        })
      }

      showCrew(id) {
          this.setState(prevState => {
            const selectedCrew = prevState.userCrew.filter(crew =>
                crew.crewToUpdate._id === id
            )[0].crewToUpdate

            return { selectedCrew };
        })

        this.openModal('Editing exisitng crew', true)
      }

      openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
         this.setState({
             modalIsOpen:true
         })
      }

      closeModal() {
        this.setState({
            modalIsOpen:false,
            selectedCrew:{}
        })
      }

      addCrew() {
        if(this.inputCrew.current.value !== -1) {
        let updatingCrew = this.state.updatingCrew;

        updatingCrew.users.push({user:this.props.userId, role:this.inputRole.current?.value});
        store.dispatch(actions.user.setUserCrew(updatingCrew))

        this.closeModal();
        }
      }

      removeCrew() {
        let updatingCrew = this.state.selectedCrew;

        const users = updatingCrew.users.filter(user => user.user !== this.props.userId);
        updatingCrew.users = users;
        store.dispatch(actions.user.setUserCrew(updatingCrew))

        this.closeModal();
      }

      renderCrewList = () => this.state.userCrew.map((crew) => (
        <tr id={crew?.crewToUpdate?._id} key={crew?.crewToUpdate?._id} onClick={() => this.showCrew(crew.crewToUpdate?._id)}>
            <td>{crew?.crewToUpdate?.name}</td>
            <td>{crew?.role}</td>
            <td className="max-width-300">{crew.crewToUpdate?.desc}</td>
        </tr>
      )); 

      handleChange(e) {
        this.setState({
          updatingCrew:this.state.crew.filter(crew => crew._id === e.target.value)[0]
        });

      }

    render() {
    return (
<div>

<Modal
          isOpen={this.state.modalIsOpen}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.state.modalTitle}</h6>
                    <div className="mT-30">
                        <div className="form-group">
                                <label htmlFor="inputCrew">Crew</label>
                                <select id="inputCrew" className="form-control" ref={this.inputCrew} onChange={(e) => this.handleChange(e)} disabled={this.state.editMode} defaultValue={this.state.selectedCrew?._id}>
                                    <option key={-1} value={-1} >--select crew--</option>
                                    {this.state.crew.map((item, index) => 
                                        <option key={index} value={item._id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputRole">Role</label>
                                <select id="inputRole" className="form-control" ref={this.inputRole} disabled={this.state.editMode} defaultValue={this.state.selectedCrew?.users?.filter(user => user.user === this.props.userId)[0]?.role}>
                                    <option value="owner">owner</option>
                                    <option value="member">member</option>
                                </select>
                            </div>

                            <div className={this.state.editMode? "control-buttons" : "control-buttons justify-to-right"} >
                            <button className={this.state.editMode? "btn btn-danger" : "hidden" } onClick={() => this.removeCrew()}>Remove</button>
                              <span className="control-buttons"> 
                               <button className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                               <button type="submit" className={!this.state.editMode? "btn btn-primary" : "hidden" } onClick={() => this.addCrew()}>Save</button>
                              </span>
                            </div>
                    </div>
                </div>
            </Modal>

    <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new crew', false)}>Add</button>
                </div>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                    {this.renderCrewList()}
                      </tbody>
                    </table>
               </div>
              </div>
            </div>


    </div>
    </div>
    )
 }
}

export default UserCrew;