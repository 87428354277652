import React from 'react';

function Footer() {
    return (
        <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
            <span>Copyright © 2020 Designed by RallyGo. All rights reserved.</span>
        </footer>
    );
}

export default Footer