import { actions } from '../actions.js';
import { checkIfExistingTokenIsValid, getExistingToken } from '../../utils/helpers/authHelper.js';

const INITIAL_STATE = {

  username: null,
  authenticated: false,
  token: null,
  previousLoginAttemptFailed: false,
  error: null,
  loading: false,
};

export const auth = (state, action) => {
  switch (action.type) {
    case actions.auth.LOGIN:
      return {
        ...state,
        login:true,
        loading:true,
      };
    case actions.auth.LOGIN_SUCCESS:
        sessionStorage.setItem('api-token', action.payload.token);
      return {
        ...state,
        username: action.payload.email,
        authenticated: true,
        previousLoginAttemptFailed:false,
        loading:false,
      };
    case actions.auth.LOGIN_ERROR:
      return {
        error: action.payload,
        authenticated: false,
        previousLoginAttemptFailed:true,
        loading:false,
      };
    case actions.global.RESET_APP:
        return checkIfExistingTokenIsValid('api-token') ? ({
          ...state,
          authenticated: true,
          username: getExistingToken().email,
          loading:false,
        }) : ({...INITIAL_STATE, loqding:false});
    case actions.auth.LOGOUT:
      sessionStorage.removeItem('api-token');
      return {
        loading:false,
        ...INITIAL_STATE,
      };
      default:
        return {
            ...state
        }
  }
};
