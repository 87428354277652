import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

const getAllPostsEpic = (action$) =>
  action$.pipe(
    ofType(actions.post.GET_ALL_POSTS),
    mergeMap(() => {
      return AxiosRallyGoAPI.posts.getAllPosts()
        .then(response => actions.post.setAllPosts(response.data))
        .catch(error => actions.post.postError(error?.response?.data?.message));
    }),
  );

  const addPostsEpic = (action$) =>
  action$.pipe(
    ofType(actions.post.ADD_POST),
    mergeMap(action => {
      return AxiosRallyGoAPI.posts.addCamping(action.payload)
        .then(() => actions.post.getAllPosts())
        .catch(error => actions.post.postError(error?.response?.data?.message));
    }),
  );

  const editPostsEpic = (action$) =>
  action$.pipe(
    ofType(actions.post.EDIT_POST),
    mergeMap(action => {
      return AxiosRallyGoAPI.posts.editCamping(action.payload)
        .then(() => actions.post.postUpdatedSuccess(action.payload))
        .catch(error => actions.post.postError(error?.response?.data?.message));
    }),
  );

  const removePostsEpic = (action$) =>
  action$.pipe(
    ofType(actions.post.REMOVE_POST),
    mergeMap(action => {
      return AxiosRallyGoAPI.posts.removeCamping(action.payload)
        .then(() => actions.post.postUpdatedSuccess())
        .catch(error => actions.post.postError(error?.response?.data?.message));
    }),
  );

  export default combineEpics(getAllPostsEpic, addPostsEpic, removePostsEpic, editPostsEpic);