import React from 'react';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js'
import { getExistingToken } from '../../utils/helpers/authHelper.js';
import './topBar.scss'
import '../shared/common.scss';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';

function displayPath (path) {
  let name =  path?.substring(1);
  return IsNullOrEmptyOrUndefined(name) ? 'Home' : name.charAt(0).toUpperCase() + name.slice(1)
}

function Topbar() {
return(
<div className="header navbar">
<div className="header-container">
  <ul className="top-bar">
<li className="top-right-spacer-12"> <span><h4>{displayPath(window.location.pathname)}</h4></span> <div> <span className="login-information">{`${getExistingToken().email}(${getExistingToken().fname})`}</span><button type="button" onClick={() => store.dispatch(actions.auth.logout())} className="btn btn-dark">Logout</button></div></li>
  </ul>
</div>
</div>
);
}

export default Topbar
