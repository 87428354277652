import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import MapPicker from 'react-google-map-picker'
import ConfirmationModal from '../shared/ConfirmationModal.js';

Modal.setAppElement('#root')


class CampingModal extends React.Component {


       state = {
        id: null,
        inputName : '',
        inputDesc : '',
        inputStreet : '',
        inputCity : '',
        inputCountry : '',
        inputCode : '',
        inputArea : '',
        location : {
            lat: 50.26099961659634, lng: 19.039669722688895
        },
        zoom: 10,
       }

    handleChangeLocation = (lat, lng) => {
       !IsNullOrEmptyOrUndefined(lat) && !IsNullOrEmptyOrUndefined(lng) ? this.setState({location:{lat:lat, lng:lng}})  : this.setState({location:{lat:this.state.location.lat, lng:this.state.location.lng}});
      }
      
    handleChangeZoom = (newZoom) => {
       !IsNullOrEmptyOrUndefined(newZoom)? this.setState({zoom:newZoom}) : this.setState({zoom:10});
      }
     

    handleSubmit = (event) => {
        event.preventDefault();
        const CampingObject = {
            _id: this.state.id,
            name:this.state.inputName,
            desc: this.state.inputDesc,
            street: this.state.inputStreet,
            city: this.state.inputCity,
            country: this.state.inputCountry,
            code: this.state.inputCode,
            area: this.state.inputArea,
            lat: this.state.location.lat,
            lng: this.state.location.lng,
        }

       this.props.editMode? store.dispatch(actions.camping.editCamping(CampingObject)) : store.dispatch(actions.camping.addCamping(CampingObject));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            id:nextProps.camping?._id,
            inputName:nextProps.camping?.name,
            inputDesc:nextProps.camping?.desc,
            inputStreet:nextProps.camping?.street,
            inputCity:nextProps.camping?.city,
            inputCountry:nextProps.camping?.country,
            inputCode:nextProps.camping?.code,
            inputArea: nextProps.camping?.area,
            location:{lat: IsNullOrEmptyOrUndefined(nextProps.camping?.lat)? 50.26099961659634 : nextProps.camping?.lat, lng: IsNullOrEmptyOrUndefined(nextProps.camping?.lng)? 19.039669722688895 : nextProps.camping?.lng},
        })
      }

    closeModal() {
        store.dispatch(actions.camping.actionError(null));
        store.dispatch(actions.camping.openCloseModal());
      }

      openConfirmationModal() {
        store.dispatch(actions.modal.openCloseConfirmationModal('Removing camping'));
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    onChangeLat = e => !IsNullOrEmptyOrUndefined(e.target.value)? this.setState({location:{lat:e.target.value}}) : null;

    onChangeLng = e => !IsNullOrEmptyOrUndefined(e.target.value)? this.setState({location:{lng:e.target.value}}) : null;



    render() {
    return (
       <div>
        <Modal
          isOpen={store.getState().camping.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                   <h6 className="c-grey-900">{this.props.modalTitle}</h6>
                    <div className="masonry-item col-md-12">
                        <form onSubmit={this.handleSubmit}>
                        <Tabs>
                     <Tab label="General inforation" className="tab">
                            <div className="form-group">
                                <label htmlFor="inputName">Name</label>
                                <input type="name" className="form-control" required id="inputName" aria-describedby="nameHelp" placeholder="Enter name" defaultValue={this.state.inputName}  onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputDesc">Description</label>
                                <input type="text" className="form-control" id="inputDesc" placeholder="Enter Description" defaultValue={this.state.inputDesc} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputStreet">Street</label>
                                <input type="text" className="form-control" required id="inputStreet" placeholder="Enter Street" defaultValue={this.state.inputStreet} onChange={this.onChange}></input>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCountry">Country</label>
                                <input type="text" className="form-control" id="inputCountry" required placeholder="Enter Country" defaultValue={this.state.inputCountry} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCity">City</label>
                                <input type="text" className="form-control" id="inputCity" required placeholder="Enter City" defaultValue={this.state.inputCity} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCode">Code</label>
                                <input type="text" className="form-control" id="inputCode" placeholder="Enter Code" defaultValue={this.state.inputCode} onChange={this.onChange}></input>
                            </div>
                            </div>

                          </Tab>
      <Tab className="tab" label="Location">
        <div>
                                        <div className="row">
                                            <section className="form-group">
                                                <label htmlFor="inputArea">Area</label>
                                                <input type="text" className="form-control" id="inputArea" placeholder="Enter Area" defaultValue={this.state.inputArea} onChange={this.onChange}></input>
                                            </section>
                                            <section className="form-group col-md-4">
                                                <label htmlFor="inputLat">Lat</label>
                                                <input type="text" className="form-control" id="inputLat" placeholder="Enter Lat" value={this.state.location.lat} onChange={this.onChangeLat}></input>
                                            </section>
                                            <section className="form-group col-md-4">
                                                <label htmlFor="inputLng">Lng</label>
                                                <input type="text" className="form-control" id="inputLng" placeholder="Enter Lng" value={this.state.location.lng} onChange={this.onChangeLng}></input>
                                            </section>
                                        </div>
  <MapPicker defaultLocation={this.state.location}
    zoom={this.state.zoom}
    style={{height:'500px'}}
    onChangeLocation={this.handleChangeLocation} 
    onChangeZoom={this.handleChangeZoom}
    apiKey='AIzaSyDLtNf7rela9D95BaxAgFPVxd8A0BOrb0k'/>
        </div>
          </Tab>
          </Tabs>
                          {!IsNullOrEmptyOrUndefined(store.getState().camping.actionError)?
<div className="alert alert-danger align-to-center max-width-600" role="alert">
<span>{store.getState().camping.actionError}</span>
                    </div> : ''}
                    <div className={this.props.editMode? "control-buttons" : "control-buttons justify-to-right"}>
                        <button type="button" className={this.props.editMode? "btn btn-danger" : "hidden" } onClick={this.openConfirmationModal}>Remove</button>
                        <span className="control-buttons">
                        <button type="button" className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </span>
                    </div>
                        </form>
                    </div>
                </div>
        </Modal>
        <ConfirmationModal 
      id={this.state.id} 
      action={actions.camping.removeCamping(this.state.id)} 
      field={this.state.inputName}
      title='Removing camping' message={'If you are sure you want to delete this camping, enter the name of camping you want to delete:'} 
       />
        
        </div>
    )
 }
}
export default CampingModal;