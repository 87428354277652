import React from 'react';
import '../shared/common.scss';
import CampingModal from './CampingModal.js';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import Pagination from 'react-js-pagination';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper';


class Campings extends React.Component {
  _isMounted = false;

    state = {
        data: [],
        pageSize:25,
        activePage:1,
        sliceFrom:0,
        rowSelected:false,
        selectedRowId:null,
        selectedCamping:null,
        modalTitle:'',
        editMode: false,
      };  

    componentDidMount() {
      this._isMounted = true;
        store.dispatch(actions.camping.getAllCampings());
        store.subscribe(() => {
          if (this._isMounted) {
            this.setState({
            data:store.getState().camping.filteredData,
             })
            }
        });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({selectedCamping:null, editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
          store.dispatch(actions.camping.openCloseModal());
      }

    clickRow(id) {
        this.openModal('Editing existing Camping', true);        
        this.setState(prevState => {

            const selectedCamping = prevState.data.filter(cmp =>
                cmp._id === id
            )[0]
            return { selectedCamping };
        })
    }

    renderCampingList = () => this.state.data?.slice(this.state.sliceFrom,this.state.sliceFrom + this.state.pageSize).map((camping, index) => (
      <tr id={camping._id} key={index} onClick={() => this.clickRow(camping._id)} className="data-row">
          <td>{camping.name}</td>
          <td>{camping.desc}</td>
          <td>{camping.street}</td>
          <td>{camping.city}</td>
          <td>{camping.country}</td>
          <td>{camping.code}</td>
          <td>{camping.area}</td>
          <td>{camping.lat}</td>
          <td>{camping.lng}</td>
      </tr>
    )); 

    handlePageChange(pageNumber) {
        this.setState(prevState => {
            return {
                ...prevState,
                activePage: pageNumber,
                sliceFrom: (pageNumber * prevState.pageSize) - prevState.pageSize
            }

        });
    }

    removeCamping() {
        store.dispatch(actions.camping.removeCamping(this.props.id));
    }

    search(e) {
        this.handlePageChange(1);
        let value = '';
        value += e.target.value;
        store.dispatch(actions.camping.searchCamping(value));
      }

    render() {
    return (
        <main className='main-content bgc-grey-100' id="root">
        <div id='mainContent'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new Camping', false)}>Add</button>
                </div>
                <input type="search" placeholder="Search..." onInput={(e) => this.search(e) }></input>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Street</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Code</th>
                          <th>Area</th>
                          <th>Lat</th>
                          <th>Lng</th>
                        </tr>
                      </thead>
                      <tbody>
                      {store.getState().camping.loading ?
                        <tr><td colSpan="9" className="align-to-center"> <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        </td>
                        </tr>
                        :
                        this.renderCampingList()}
                    </tbody>
                    </table>
                    <div className="table-footer">
                    <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing {this.state.activePage} to {this.state.pageSize} of {this.state.data?.length} entries</div>
                    <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
          activePage={this.state.activePage}
          itemsCountPerPage={IsNullOrEmptyOrUndefined(this.state.pageSize)? 0 : this.state.pageSize}
          totalItemsCount={IsNullOrEmptyOrUndefined(this.state.data?.length)? 0 : this.state.data?.length}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <CampingModal 
      camping={this.state.selectedCamping}
      modalTitle={this.state.modalTitle}
      editMode={this.state.editMode} />
      </main>
    )
 }
}

export default Campings;