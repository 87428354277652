import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';

const getAllOrganizationsEpic = (action$) =>
  action$.pipe(
    ofType(actions.organization.GET_ALL_ORGANIZATIONS),
    mergeMap(action => {
      return AxiosRallyGoAPI.organizations.getAllOrganizations()
        .then(response => actions.organization.setAllOrganizations(response.data))
        .catch(error => actions.organization.organizationsError(error.message));
    }),
  );

  const editOrganizationEpic = (action$) =>
  action$.pipe(
    ofType(actions.organization.EDIT_ORGANIZATION),
    mergeMap(action => {
      return AxiosRallyGoAPI.organizations.editOrganization(action.payload)
        .then(() => actions.organization.organizationUpdatedWithSuccess())
        .catch(error => actions.organization.organizationsError(error.message));
    }),
  );


  export default combineEpics(getAllOrganizationsEpic, editOrganizationEpic);