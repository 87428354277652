export const GET_ALL_USERS = 'user/GET_ALL_USERS';
export const GET_USER_ROLES = 'user/GET_USER_ROLES';
export const GET_USER_ORGANIZATIONS = 'user/GET_USER_ORGANIZATIONS';
export const ADD_USER_ROLE = 'user/ADD_USER_ROLE';
export const REMOVE_USER_ROLE = 'user/REMOVE_USER_ROLE';
export const SET_ALL_USERS = 'user/SET_ALL_USERS';
export const SET_USER_ROLES = 'user/SET_USER_ROLES';
export const SET_USER_ORGANIZATIONS = 'user/SET_USER_ORGANIZATIONS';
export const SET_USER_CREW = 'user/SET_USER_CREW';
export const SET_USER = 'user/SET_USER';
export const SET_EMAIL = 'user/SET_EMAIL';
export const SET_FNAME = 'user/SET_FNAME';
export const SET_LNAME = 'user/SET_LNAME';
export const SET_PASSWD = 'user/SET_PASSWD';
export const RESET_USER = 'user/RESET_USER';
export const ADD_USER = 'user/ADD_USER';
export const REMOVE_USER = 'user/REMOVE_USER';
export const EDIT_USER = 'user/EDIT_USER';
export const SEARCH_USER = 'user/SEARCH_USER';
export const USER_UPDATED_SUCCESS = 'user/USER_UPDATED_SUCCESS';
export const USER_ADDED_SUCCESS = 'user/USER_ADDED_SUCCESS';
export const USER_REMOVED_SUCCESS = 'user/USER_REMOVED_SUCCESS';
export const USER_ERROR = 'user/USER_ERROR';
export const ACTION_ERROR = 'user/ACTION_ERROR';
export const OPEN_CLOSE_MODAL = 'user/OPEN_CLOSE_MODAL';

export const getAllUsers = () => ({
    type:GET_ALL_USERS
})

export const getUserRoles = (payload) => ({
    type:GET_USER_ROLES,
    payload
})

export const getUserOrganizations = (payload) => ({
    type:GET_USER_ORGANIZATIONS,
    payload
})

export const addUserRole = (payload) => ({
    type:ADD_USER_ROLE,
    payload
})

export const removeUserRole = (payload) => ({
    type:REMOVE_USER_ROLE,
    payload
})
export const setAllUsers = (payload) => ({
    type:SET_ALL_USERS,
    payload
})

export const setUserRoles = (payload) => ({
    type:SET_USER_ROLES,
    payload
})

export const setUserOrganizations = (payload) => ({
    type:SET_USER_ORGANIZATIONS,
    payload
})

export const setUserCrew = (payload) => ({
    type:SET_USER_CREW,
    payload
})

export const setUser = (payload) => ({
    type:SET_USER,
    payload
})

export const resetUser = () => ({
    type:RESET_USER
})

export const addUser = (payload) => ({
    type:ADD_USER,
    payload
})

export const editUser = (payload) => ({
    type:EDIT_USER,
    payload
})

export const removeUser = (payload) => ({
    type:REMOVE_USER,
    payload
})

export const searchUser = (payload) => ({
    type:SEARCH_USER,
    payload
})

export const userUpdatedSuccess = (payload) => ({
    type: USER_UPDATED_SUCCESS,
    payload
})

export const userRemovedSuccess = (payload) => ({
    type: USER_REMOVED_SUCCESS,
    payload
})

export const userAddedSuccess = (payload) => ({
    type: USER_ADDED_SUCCESS,
    payload
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const userError = (payload) => ({
    type: USER_ERROR,
    payload
});

export const actionError = (payload) => ({
    type: ACTION_ERROR,
    payload
});