export const OPEN_CLOSE_MODAL = 'modal/OPEN_CLOSE_MODAL';
export const OPEN_CLOSE_CONFIRMATION_MODAL = 'modal/OPEN_CLOSE_CONFIRMATION_MODAL';

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const openCloseConfirmationModal = () => ({
    type:OPEN_CLOSE_CONFIRMATION_MODAL,
})
