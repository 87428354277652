import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import { BOOTSTRAP_CLASSES } from '../../utils/formatters/cmponentsFormatter.js';
import FilteredMultiSelect from 'react-filtered-multiselect';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import UserCrew from './UserCrew.js';
import UserOrganizations from './UserOrganizations.js';
import ConfirmationModal from '../shared/ConfirmationModal.js';

Modal.setAppElement('#root')

class UserModal extends React.Component {

    state = {
        userRoles:[],
        roles:[{role:"roles/platform.admin",_id:"5fa6d628bf0a59000bd706a4"},{role:"roles/platform.user",_id:"5f6cb86440e8d6000a859b7e"}],
        allCrew:[],
        allOrganizations:[],
        userCrew:[],
        updatingCrew:null,
        updatingOrganization: null,
        userOrganizations:[],
        rolesSelectedOptions:[] ,
        rolesToRemove:[],
        rolesToAdd:[],
        id:null,
        inputEmail:'',
        inputFname:'',
        inputLname:'',
        inputPasswd:'',
        inputStatus:'pending',
      }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userRoles: IsNullOrEmptyOrUndefined(nextProps.roles)? [] : nextProps.roles,
            rolesSelectedOptions: IsNullOrEmptyOrUndefined(nextProps.roles)? [] : nextProps.roles,
            allCrew: IsNullOrEmptyOrUndefined(nextProps.allCrew)? [] : nextProps.allCrew,
            userCrew: IsNullOrEmptyOrUndefined(nextProps.allCrew)? [] : this.setUserCrew(nextProps.allCrew, nextProps.user?._id),
            updatingCrew: nextProps.updatingCrew,
            updatingOrganization: nextProps.updatingOrganization,
            allOrganizations: IsNullOrEmptyOrUndefined(nextProps.allOrganizations)? [] : nextProps.allOrganizations,
            userOrganizations: IsNullOrEmptyOrUndefined(nextProps.allOrganizations)? [] : this.setUserOrganizations(nextProps.allOrganizations, nextProps.user?._id),
            id:nextProps.user?._id,
            inputEmail:nextProps.user?.email,
            inputFname:nextProps.user?.fname,
            inputLname:nextProps.user?.lname,
            inputPasswd:nextProps.user?.passwd,
            inputStatus:!IsNullOrEmptyOrUndefined(nextProps.crew?.status)? nextProps.user?.status : this.state.inputStatus,
        })
      }

    handleSubmit = (event) => {
        event.preventDefault();

        const userObject = {
           _id: this.props.user?._id,
            email:this.state.inputEmail,
            fname: this.state.inputFname,
            lname: this.state.inputLname,
            status: this.state.inputStatus,
        }

        if(!IsNullOrEmptyOrUndefined(this.state.inputPasswd)) {
            userObject.passwd = this.state.inputPasswd;
        }

        if (this.props.editMode) {
            if (!IsNullOrEmptyOrUndefined(this.state.updatingCrew)) {
                store.dispatch(actions.crew.editCrew(this.state.updatingCrew))
            }
            if (!IsNullOrEmptyOrUndefined(this.state.updatingOrganization)) {
                store.dispatch(actions.organization.editOrganization(this.state.updatingOrganization))
            }
            store.dispatch(actions.user.editUser(userObject))

        } else {
            store.dispatch(actions.user.addUser(userObject));
        }

        this.updateRoles();

    }

    updateRoles() {

        var selectedRolesName = this.state.rolesSelectedOptions.map(item => item.role);
        var userRoles = this.state.userRoles.map(item => item.role);

        var rolesToAdd = selectedRolesName.filter(function (el) {
            return userRoles.indexOf(el) < 0;
        });

        if (rolesToAdd.length > 0) {
            rolesToAdd.map(roleName => {
                const userObject = {
                    _id: this.state.id,
                    role: { role: roleName }
                }
                return store.dispatch(actions.user.addUserRole(userObject));
            })
        }

    }

    setUserCrew(data, userid) {
          const userCrew = data?.filter(crew => crew?.users?.some(user => user?.user === userid));

          let userCrewObject = [];
           userCrew.map(crew => {
            return userCrewObject.push(
                 {
                     role:crew?.users.filter(user => user.user === userid)[0]?.role,
                     crewToUpdate:crew
                 }
             )
          })

          return userCrewObject;
      }

    setUserOrganizations(data, userid) {
        const userOrganizations = data?.filter(organization => organization?.users?.includes(userid));

        let userOrganizationObject = [];
        userOrganizations.map(organization => {
          return userOrganizationObject.push(
               {
                   organizationToUpdate:organization
               }
           )
        })

        return userOrganizationObject;
    }

    closeModal() {
        store.dispatch(actions.user.actionError(null));
        store.dispatch(actions.user.openCloseModal());
      }

    roleHandleDeselect = (deselectedOptions) => {
        this.state.rolesToRemove.push(deselectedOptions[0]._id);
        var rolesSelectedOptions = this.state.rolesSelectedOptions.slice()
        deselectedOptions.forEach(option => {
            rolesSelectedOptions.splice(rolesSelectedOptions.indexOf(option), 1)
        })
        this.setState({rolesSelectedOptions})

          const userObject = deselectedOptions.map(roleId => {
              return {
                  _id: this.state.id,
                  role_id: roleId._id
              }
          })

           store.dispatch(actions.user.removeUserRole(userObject[0]));
      }

    roleHandleSelect = (rolesSelectedOptions) => {
        rolesSelectedOptions.sort((a, b) => a.id - b.id)
        var selectedRolesName = rolesSelectedOptions.map(item => item.role);
        var userRoles = this.state.userRoles.map(item => item.role);


        var rolesToAdd = selectedRolesName.filter(function (el) {
            return userRoles.indexOf(el) < 0;
        });

        if (rolesToAdd.length > 0) {
            rolesToAdd.map(roleName => {
                const userObject = {
                    _id: this.state.id,
                    role: { role: roleName }
                }
                return store.dispatch(actions.user.addUserRole(userObject));
            })
        }
      }

    removeUser = () => {
        store.dispatch(actions.user.removeUser(this.props.user?._id));
        this.closeModal();
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    openConfirmationModal() {
        store.dispatch(actions.modal.openCloseConfirmationModal('Removing user'));
    }

    render() {
    const {roles, rolesSelectedOptions } = this.state;
    return (

       <div>
        <Modal
          isOpen={store.getState().user.modalIsOpen}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                   <h6 className="c-grey-900">{this.props.modalTitle}</h6>
                   <form onSubmit={this.handleSubmit}>
<Tabs>
    <Tab label="General inforation" className="tab">

                            <div className="form-group">
                                <label htmlFor="inputEmail">Email address</label>
                                <input type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" required placeholder="Enter email" defaultValue={this.state.inputEmail} onChange={this.onChange} ></input>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFname">First Name</label>
                                <input type="text" className="form-control" id="inputFname" placeholder="Enter First Name" required defaultValue={this.state.inputFname}  onChange={this.onChange}></input>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLname">Last Name</label>
                                <input type="text" className="form-control" id="inputLname" placeholder="Enter Last Name" required defaultValue={this.state.inputLname}  onChange={this.onChange}></input>
                            </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPasswd">Password</label>
                                <input type="password" className="form-control" id="inputPasswd" placeholder="Enter Password" defaultValue={this.state.inputPasswd} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputStatus">Status</label>
                                <select id="inputStatus" className="form-control" defaultValue={this.state.inputStatus} onChange={this.onChange}>
                                    <option>pending</option>
                                    <option>active</option>
                                    <option>blocked</option>
                                </select>
                            </div>
                            </div>

    </Tab>
    <Tab label="Roles" disabled={!this.props.editMode} className="tab">
    <div className="form-group col-md-12">
      <div className="control-buttons margin-top-10">
        <FilteredMultiSelect
          showFilter = {true}
          buttonText="Add"
          classNames={BOOTSTRAP_CLASSES}
          onChange={this.roleHandleSelect}
          options={ roles }
          selectedOptions={rolesSelectedOptions}
          textProp="role"
          valueProp="role"
        />
        <FilteredMultiSelect
          id="inputRoles"
          buttonText="Remove"
          classNames={BOOTSTRAP_CLASSES}
          onChange={this.roleHandleDeselect}
          options={rolesSelectedOptions}
          textProp="role"
          valueProp="role"
          ref={this.inputRoles}
        />
        </div>
    </div>
    </Tab>

    <Tab label="Crew" disabled={!this.props.editMode} className="tab">

    <UserCrew data={this.state.userCrew} crew={this.state.allCrew} userId={this.props.user?._id} />

    </Tab>

    <Tab label="Organizations" disabled={!this.props.editMode} className="tab">

     <UserOrganizations data={this.state.userOrganizations} organizations={this.state.allOrganizations} userId={this.props.user?._id} />

    </Tab>

</Tabs>
{!IsNullOrEmptyOrUndefined(store.getState().user.actionError)?
<div className="alert alert-danger align-to-center max-width-600" role="alert">
<span>{store.getState().user.actionError}</span>
                    </div> : ''}
                    <div className={this.props.editMode? "control-buttons" : "control-buttons justify-to-right"}>
                        <button type="button" className={this.props.editMode? "btn btn-danger" : "hidden" } onClick={this.openConfirmationModal}>Remove</button>
                        <span className="control-buttons">
                        <button type="button" className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </span>
                    </div>
</form>

        </Modal>
        <ConfirmationModal 
      id={this.props.user?._id} 
      action={actions.user.removeUser(this.props.user?._id)} 
      field={this.state.inputEmail}
      title='Removing user' message={'If you are sure you want to delete this account, enter the email address you want to delete:'} 
       />
        </div>
    )
 }
}

export default UserModal;