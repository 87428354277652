import { actions } from '../actions.js';

const INITIAL_STATE = {
    loading: true,
    error: null,
    filteredData: null,
    data: null,
    modalIsOpen: false,
    selectedPost: {
        _id: null,
        title: '',
        content: ''
    }
};


export const post = (state, action) => {
    switch (action.type) {
        case actions.post.GET_ALL_POSTS:
            return {
                ...state,
                filteredData: [],
                data: [],
                selectedPost: {},
                loading: true,
                error: null,
                modalIsOpen: false,
            };
        case actions.post.SET_ALL_POSTS:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: null
            }
        case actions.post.SET_POST:
            return {
                ...state,
                selectedPost: action.payload,
            }
        case actions.post.EDIT_POST:
            return {
                ...state,
                data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                filteredData: state.data.map(item => item._id === action.payload._id ? action.payload : item),

            }
        case actions.post.REMOVE_POST:
            return {
                ...state,
                data: state.data.filter((item) => item._id !== action.payload),
                filteredData: state.data.filter((item) => item._id !== action.payload),
            }
        case actions.post.SEARCH_POST:
            return {
                ...state,
                filteredData: action.payload.length > 2 ? state.data.filter((item) =>
                    item.title?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.content?.toLowerCase().includes(action.payload.toLowerCase())
                ) : [...state.data]
            }
        case actions.post.POST_UPDATED_SUCCESS:
            return {
                ...state,
                modalIsOpen: false,
                error:null,
            }
        case actions.post.POST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actions.post.OPEN_CLOSE_MODAL:
            return {
                ...state,
                modalIsOpen: !state.modalIsOpen,
            };
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
            };
        default:
            return {
                ...state
            }

    }
}