import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import  AxiosRallyGoAPI  from '../../api/RallyGoApi.js';
import { actions } from '../actions.js';



const getAllUsersEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.GET_ALL_USERS),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.getAllUsers()
        .then(response => actions.user.setAllUsers(response.data))
        .catch(error => actions.user.userError(error.response.data.error));
    }),
  );

  const addUsersEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.ADD_USER),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.addUser(action.payload)
        .then(response => actions.user.userAddedSuccess(response.data))
        .catch(error => actions.user.actionError(error.response));
    }),
  );

  const addUserRoleEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.ADD_USER_ROLE),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.addUserRole(action.payload)
        .then(response => actions.user.getUserRoles(action.payload._id))
        .catch(error => actions.user.actionError(error.response.data.error));
    }),
  );

  const removeUserRoleEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.REMOVE_USER_ROLE),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.removeUserRole(action.payload)
        .then(response => actions.user.getUserRoles(action.payload._id))
        .catch(error => actions.user.actionError(error.response.data.error));
    }),
  );

  const editUsersEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.EDIT_USER),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.editUser(action.payload)
        .then(response => actions.user.userUpdatedSuccess(response.data))
        .catch(error => actions.user.actionError(error.response.data.error));
    }),
  );

  const removeUsersEpic = (action$) =>
  action$.pipe(
    ofType(actions.user.REMOVE_USER),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.removeUser(action.payload)
        .then(response => actions.user.userRemovedSuccess(response.data._id))
        .catch(error => actions.user.actionError(error.message));
    }),
  );

  const getUserRoles = (action$) =>
  action$.pipe(
    ofType(actions.user.GET_USER_ROLES),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.getUserRoles(action.payload)
        .then(response => actions.user.setUserRoles(response.data))
        .catch(error => actions.user.userError(error.message));
    }),
  );

  const getUserOrganizations = (action$) =>
  action$.pipe(
    ofType(actions.user.GET_USER_ORGANIZATIONS),
    mergeMap(action => {
      return AxiosRallyGoAPI.users.getUserOrganizations(action.payload)
        .then(response => actions.user.setUserOrganizations(response.data))
        .catch(error => actions.user.userError(error.message));
    }),
  );

  export default combineEpics(getAllUsersEpic, addUsersEpic, removeUsersEpic, editUsersEpic, getUserRoles, addUserRoleEpic, removeUserRoleEpic, getUserOrganizations);