export const GET_ALL_CAMPINGS = 'camping/GET_ALL_CAMPINGS';
export const SET_ALL_CAMPINGS = 'camping/SET_ALL_CAMPINGS';
export const RESET_CAMPING = 'camping/RESET_CAMPING';
export const ADD_CAMPING = 'camping/ADD_CAMPING';
export const REMOVE_CAMPING = 'camping/REMOVE_CAMPING';
export const EDIT_CAMPING = 'camping/EDIT_CAMPING';
export const SEARCH_CAMPING = 'camping/SEARCH_CAMPING';
export const CAMPING_ADDED_SUCCESS = 'camping/CAMPING_ADDED_SUCCESS';
export const CAMPING_UPDATED_SUCCESS = 'camping/CAMPING_UPDATED_SUCCESS';
export const CAMPING_REMOVED_SUCCESS = 'camping/CAMPING_REMOVED_SUCCESS';
export const CAMPING_ERROR = 'camping/CAMPING_ERROR';
export const ACTION_ERROR = 'camping/ACTION_ERROR';
export const OPEN_CLOSE_MODAL = 'camping/OPEN_CLOSE_MODAL';

export const getAllCampings = () => ({
    type:GET_ALL_CAMPINGS
})

export const setAllCampings = (payload) => ({
    type:SET_ALL_CAMPINGS,
    payload
})

export const addCamping = (payload) => ({
    type:ADD_CAMPING,
    payload
})

export const editCamping = (payload) => ({
    type:EDIT_CAMPING,
    payload
})

export const removeCamping = (payload) => ({
    type:REMOVE_CAMPING,
    payload
})

export const campingAddedSuccess = (payload) => ({
    type: CAMPING_ADDED_SUCCESS,
    payload
})

export const campingUpdatedSuccess = (payload) => ({
    type: CAMPING_UPDATED_SUCCESS,
    payload
})

export const campingRemovedSuccess = (payload) => ({
    type: CAMPING_REMOVED_SUCCESS,
    payload
})

export const searchCamping = (payload) => ({
    type:SEARCH_CAMPING,
    payload
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const campingError = (payload) => ({
    type: CAMPING_ERROR,
    payload
});

export const actionError = (payload) => ({
    type: ACTION_ERROR,
    payload
});