export const GET_ALL_CREW = 'crew/GET_ALL_CREW';
export const SET_ALL_CREW = 'crew/SET_ALL_CREW';
export const SET_CREW_MEMBERS = 'crew/SET_CREW_MEMBERS';
export const SET_CREW_CONTACTS = 'crew/SET_CREW_CONTACTS';
export const SET_CREW = 'crew/SET_CREW';
export const RESET_CREW = 'crew/RESET_CREW';
export const ADD_CREW = 'crew/ADD_CREW';
export const REMOVE_CREW = 'crew/REMOVE_CREW';
export const EDIT_CREW = 'crew/EDIT_CREW';
export const EDIT_CREW_STATE = 'crew/EDIT_CREW_STATE';
export const REMOVE_CREW_MEMBER = 'crew/REMOVE_CREW_MEMBER';
export const REMOVE_CREW_CONTACT = 'crew/REMOVE_CREW_CONTACT';
export const SEARCH_CREW = 'crew/SEARCH_CREW';
export const CREW_ERROR = 'crew/CREW_ERROR';
export const ACTION_ERROR = 'crew/ACTION_ERROR';
export const CREW_UPDATED_SUCCESS = 'crew/CREW_UPDATED_SUCCESS';
export const CREW_ADDED_SUCCESS = 'crew/CREW_ADDED_SUCCESS';
export const CREW_REMOVED_SUCCESS = 'crew/CREW_REMOVED_SUCCESS';
export const OPEN_CLOSE_MODAL = 'crew/OPEN_CLOSE_MODAL';

export const getAllCrew = () => ({
    type:GET_ALL_CREW
})

export const setCrewMembers = (payload) => ({
    type:SET_CREW_MEMBERS,
    payload
})

export const setCrewContacts = (payload) => ({
    type:SET_CREW_CONTACTS,
    payload
})

export const setAllCrew = (payload) => ({
    type:SET_ALL_CREW,
    payload
})

export const setCrew = (payload) => ({
    type:SET_CREW,
    payload
})

export const resetCrew = () => ({
    type:RESET_CREW
})

export const addCrew = (payload) => ({
    type:ADD_CREW,
    payload
})

export const editCrew = (payload) => ({
    type:EDIT_CREW,
    payload
})

export const removeCrewMember = (payload) => ({
    type:REMOVE_CREW_MEMBER,
    payload
})

export const removeCrewContact = (payload) => ({
    type:REMOVE_CREW_CONTACT,
    payload
})

export const removeCrew = (payload) => ({
    type:REMOVE_CREW,
    payload
})

export const searchCrew = (payload) => ({
    type:SEARCH_CREW,
    payload
})

export const crewUpdatedSuccess = (payload) => ({
    type: CREW_UPDATED_SUCCESS,
    payload
})

export const crewRemovedSuccess = (payload) => ({
    type: CREW_REMOVED_SUCCESS,
    payload
})

export const crewAddedSuccess = (payload) => ({
    type: CREW_ADDED_SUCCESS,
    payload
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const crewError = (payload) => ({
    type: CREW_ERROR,
    payload
});

export const actionError = (payload) => ({
    type: ACTION_ERROR,
    payload
});