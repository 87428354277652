import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import '../shared/common.scss'
import {Tabs, Tab} from 'react-bootstrap-tabs';
import CrewContacts from './CrewContacts.js';
import CrewMembers from './CrewMembers.js';
import CrewUsers from './CrewUsers.js';
import ConfirmationModal from '../shared/ConfirmationModal.js';

Modal.setAppElement('#root')

class CrewModal extends React.Component {

    constructor() {
        super();
        this.state = {
            id:null,
            inputName:'',
            inputDesc:'',
            inputAvatar:'',
            inputCity:'',
            inputCountry:'',
            crewUsers:[] ,
            }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            id:nextProps.crew?._id,
            inputName:nextProps.crew?.name,
            inputDesc:nextProps.crew?.desc,
            inputAvatar:nextProps.crew?.avatar,
            inputCity:nextProps.crew?.city,
            inputCountry:nextProps.crew?.country,
        })
      }

      handleSubmit = (event) => {
        event.preventDefault();
        const crew = store.getState().crew?.selectedCrew;
        const crewObject = {
            _id: this.state.id,
            name:this.state.inputName,
            desc: this.state.inputDesc,
            avatar: this.state.inputAvatar,
            city: this.state.inputCity,
            country: this.state.inputCountry,
            users: IsNullOrEmptyOrUndefined(crew.users)? [] : crew.users.map(user => {return { _id: IsNullOrEmptyOrUndefined(user._id)? user.id : user._id, user: user.user, role:user.role}}),
            members:crew.members,
            contact:crew.contact,
            
        }

       this.props.editMode? store.dispatch(actions.crew.editCrew(crewObject)) : store.dispatch(actions.crew.addCrew(crewObject));
    }

    closeModal() {
        store.dispatch(actions.crew.actionError(null));
        store.dispatch(actions.crew.openCloseModal());
      }

    openConfirmationModal() {
        store.dispatch(actions.modal.openCloseConfirmationModal('Removing team'));
    }

    removeCrew = () => {   
        store.dispatch(actions.crew.removeCrew(this.state.id))
        this.closeModal();
    };

    onChange = e => this.setState({ [e.target.id]: e.target.value })
  
    render() {
    return (
       <div>
        <Modal
          isOpen={store.getState().crew.modalIsOpen}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.props.modalTitle}</h6>
                    <div className="mT-30">
                        <form onSubmit={this.handleSubmit}>
                        <Tabs>
    <Tab label="General inforation" className="tab">
                            <div className="form-group">
                                <label htmlFor="inputName">Name</label>
                                <input type="text" required className="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter name"  defaultValue={this.state.inputName} onChange={this.onChange}  ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputDesc">Description</label>
                                <input type="text" className="form-control" id="inputDesc" placeholder="Enter Description" defaultValue={this.state.inputDesc} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputAvatar">Avatar</label>
                                <input type="text" className="form-control" id="inputAvatar" placeholder="Enter Avatar" defaultValue={this.state.inputAvatar}  onChange={this.onChange}></input>
                            </div>
                            <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCity">City</label>
                                <input type="text" className="form-control" id="inputCity" placeholder="Enter City" defaultValue={this.state.inputCity} onChange={this.onChange}></input>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCountry">Country</label>
                                <input type="text" className="form-control" id="inputCountry" placeholder="Enter Country" defaultValue={this.state.inputCountry} onChange={this.onChange}></input>
                            </div>
                            </div>
                            </Tab>
      <Tab label="Users" disabled={!this.props.editMode} className="tab">
      <CrewUsers />
      </Tab>
      <Tab label="Members" disabled={!this.props.editMode} className="tab">
      <CrewMembers />
      </Tab>

      <Tab label="Contacts" disabled={!this.props.editMode} className="tab">
      <CrewContacts />
      </Tab>
      </Tabs>
      {!IsNullOrEmptyOrUndefined(store.getState().crew.actionError)?
<div className="alert alert-danger align-to-center max-width-600" role="alert">
<span>{store.getState().crew.actionError}</span>
                    </div> : ''}
                    <div className={this.props.editMode? "control-buttons" : "control-buttons justify-to-right"}>
                        <button type="button" className={this.props.editMode? "btn btn-danger" : "hidden" } onClick={this.openConfirmationModal}>Remove</button>
                        <span className="control-buttons">
                        <button className="btn btn-primary" type="button" onClick={() => this.closeModal()}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </span>
                    </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <ConfirmationModal 
      id={this.state.id} 
      action={actions.crew.removeCrew(this.state.id)} 
      field={this.state.inputName}
      title='Removing team' message={'If you are sure you want to delete this team, enter the name of team you want to delete:'} 
       />
        </div>
    )
 }
}

export default CrewModal;