import { toast } from 'react-toastify';
import { IsNullOrEmptyOrUndefined, setErrorMessage } from '../../utils/helpers/validatorHelper.js';
import { actions } from '../actions.js';

const INITIAL_STATE = {
    loading: true,
    error: null,
    actionError: null,
    filteredData: null,
    data: null,
    modalIsOpen: false,
    selectedCamping: {
        _id: null,
        name: '',
        desc: '',
        street: '',
        city: '',
        country: '',
        code: '',
        area: '',
        lat: 0,
        lng: 0,
    }
};


export const camping = (state, action) => {
    switch (action.type) {
        case actions.camping.GET_ALL_CAMPINGS:
            return {
                ...state,
                filteredData: [],
                data: [],
                selectedCamping: {},
                loading: true,
                error: null,
                actionError: null,
                modalIsOpen: false,
            };
        case actions.camping.SET_ALL_CAMPINGS:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: null,
                actionError: null,
            }
        case actions.camping.SET_CAMPING:
            return {
                ...state,
                selectedCamping: action.payload,
            }
        case actions.camping.CAMPING_ADDED_SUCCESS:
            toast.success("Camping added with success!");
            return {
                ...state,
                data: [...state.data, action.payload],
                filteredData: [...state.data, action.payload],
                modalIsOpen: false,
            }
        case actions.camping.CAMPING_UPDATED_SUCCESS:
            toast.success("Camping updated with success!");
            return {
                ...state,
                data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                filteredData: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                modalIsOpen: false,
            }
        case actions.camping.CAMPING_REMOVED_SUCCESS:
            toast.success("Camping removed with success!");
            return {
                ...state,
                data: state.data.filter((item) => item._id !== action.payload),
                filteredData: state.data.filter((item) => item._id !== action.payload),
                modalIsOpen: false,
            }
        case actions.camping.SEARCH_CAMPING:
            return {
                ...state,
                filteredData: action.payload.length > 2 ? state.data.filter((item) =>
                    item.name?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.desc?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.street?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.city?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.country?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.code?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.area?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.lat === action.payload
                    || item.lng === action.payload
                ) : [...state.data]
            }
        case actions.camping.CAMPING_ERROR:
            toast.error("Internal Server Error: "+IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, false));
            return {
                ...state,
                loading: false,
                error: IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, false)
            };
        case actions.camping.ACTION_ERROR:
            if(!IsNullOrEmptyOrUndefined(action.payload)) toast.error(setErrorMessage(action.payload, false));
            return {
                ...state,
                loading: false,
                actionError: IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, false)
            };
        case actions.camping.OPEN_CLOSE_MODAL:
            return {
                ...state,
                modalIsOpen: !state.modalIsOpen,
            };
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
            };
        default:
            return {
                ...state
            }

    }
}