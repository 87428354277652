import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';

Modal.setAppElement('#root')

class ConfirmationModal extends React.Component {

    state = {
        input:'',
        isCorrectAnswer: true
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    handleSubmit = (event) => {
        event.preventDefault(); 
        this.checkAnswer();
        if(this.props.field === this.state.input) {
        store.dispatch(this.props.action);
        this.closeModal();
        } 
    }

    closeModal() {
        this.setState({isCorrectAnswer:true})
        store.dispatch(actions.modal.openCloseConfirmationModal());
      }

    checkAnswer() {
    return this.props.field === this.state.input? this.setState({isCorrectAnswer:true}) : this.setState({isCorrectAnswer:false});
    }

    render() {
        const { isCorrectAnswer } = this.state;
    return (
       <div>
        <Modal
          isOpen={store.getState().modal.confirmationModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                   <h6 className="c-grey-900">{this.props.title}</h6>
                    <div className="masonry-item col-md-12">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                            {this.props.message}
                            </div>
                            <div className="form-group" >
                                <input type="text" className="form-control" id="input" placeholder="Enter your answer" required  onChange={this.onChange}></input>
                            </div>
                            { !isCorrectAnswer?
                            <div className="alert alert-danger align-to-center max-width-600" role="alert">Wrong answer!</div>
                            : ''
                            }
                            <div className="control-panel justify-to-right">
                                <button  className="btn btn-primary" onClick={() => this.closeModal()}>No</button>
                                <button type="submit" className="btn btn-primary">Yes</button>
                            </div>
                        </form>
                    </div>
                </div>
        </Modal>
        </div>
    )
 }
}

export default ConfirmationModal;