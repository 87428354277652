import {IsNullOrEmptyOrUndefined} from './validatorHelper.js';

export const getExistingToken = () => {
    const rawTokenFromStorage = sessionStorage.getItem('api-token');
    return IsNullOrEmptyOrUndefined(rawTokenFromStorage)
      ? null
      : JSON.parse(atob(rawTokenFromStorage.split('.')[1]));
  };

  export const getToken = () => {
    return sessionStorage.getItem('api-token');
  }

  export const checkIfExistingTokenIsValid = () => {
    const now = Date.now().valueOf() / 1000;
    const existingToken = getExistingToken('api-token');
    return !IsNullOrEmptyOrUndefined(existingToken) && existingToken.exp > now;
  };