export const GET_ALL_ORGANIZATIONS = 'organization/GET_ALL_ORGANIZATIONS';
export const SET_ALL_ORGANIZATIONS = 'organization/SET_ALL_ORGANIZATIONS';
export const EDIT_ORGANIZATION = 'organization/EDIT_ORGANIZATION';
export const ORGANIZATIONS_ERROR = 'organization/ORGANIZATIONS_ERROR';
export const ORGANIZATION_UPDATED_WITH_SUCCESS = 'organization/ORGANIZATION_UPDATED_WITH_SUCCESS';
export const OPEN_CLOSE_MODAL = 'organization/OPEN_CLOSE_MODAL';


export const getAllOrganizations = () => ({
    type:GET_ALL_ORGANIZATIONS
})

export const setAllOrganizations = (payload) => ({
    type:SET_ALL_ORGANIZATIONS,
    payload
})

export const editOrganization = (payload) => ({
    type:EDIT_ORGANIZATION,
    payload
})

export const organizationUpdatedWithSuccess = () => ({
type: ORGANIZATION_UPDATED_WITH_SUCCESS
})

export const openCloseModal = () => ({
    type:OPEN_CLOSE_MODAL,
})

export const organizationsError = (payload) => ({
    type: ORGANIZATIONS_ERROR,
    payload
});