import axios from "axios";
import { getToken } from '../utils/helpers/authHelper.js';

//const BASE_URL = "https://api.dev.rallygo.eu/";
const BASE_URL = process.env.REACT_APP_RGO_API;

const HTTP_HEADERS = {
    header: () => {
      return {headers: { "x-access-token": getToken() }};
    }}

const AxiosRallyGoAPI = {
    users: {
        getAllUsers: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/users', HTTP_HEADERS.header());
            return response;
        },
        addUser: async function (userToAdd) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/users', userToAdd, HTTP_HEADERS.header());
            return response;
        },
        editUser: async function (userToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/users/' + userToEdit._id, userToEdit, HTTP_HEADERS.header());
            return response;
        },
        removeUser: async function (userId) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/users/' + userId, HTTP_HEADERS.header());
            return response;
        },
        getUserRoles: async function (userId) {
            const response = await axios.get(BASE_URL + '/api/v3/admin/users/'+userId+'/roles', HTTP_HEADERS.header());
            return response;
        },
        addUserRole: async function (user) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/users/'+user._id+'/roles',user.role, HTTP_HEADERS.header());
            return response;
        },
        removeUserRole: async function (user) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/users/'+user._id+'/roles/'+ user.role_id, HTTP_HEADERS.header());
            return response;
        },
        getUserOrganizations: async function (userId) {
            const response = await axios.get(BASE_URL + '/api/v3/admin/organizations?user='+userId, HTTP_HEADERS.header());
            return response;
        },
    },
    campings: {
        getAllCampings: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/campings', HTTP_HEADERS.header());
            return response;
        },
        addCamping: async function (campingToAdd) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/campings', campingToAdd, HTTP_HEADERS.header());
            return response;
        },
        editCamping: async function (campingToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/campings/' + campingToEdit._id, campingToEdit, HTTP_HEADERS.header());
            return response;
        },
        removeCamping: async function (campingId) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/campings/' + campingId, HTTP_HEADERS.header());
            return response;
        }
    } ,
    events: {
        getAllEvents: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/events', HTTP_HEADERS.header());
            return response;
        },
        addEvent: async function (eventToAdd) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/events', eventToAdd, HTTP_HEADERS.header());
            return response;
        },
        editEvent: async function (eventToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/events/' + eventToEdit._id, eventToEdit, HTTP_HEADERS.header());
            return response;
        },
        removeEvent: async function (eventId) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/events/' + eventId, HTTP_HEADERS.header());
            return response;
        }
    } ,
    crew: {
        getAllCrew: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/teams', HTTP_HEADERS.header());
            return response;
        },
        addCrew: async function (crewToAdd) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/teams', crewToAdd, HTTP_HEADERS.header());
            return response;
        },
        editCrew: async function (crewToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/teams/' + crewToEdit._id, crewToEdit, HTTP_HEADERS.header());
            return response;
        },
        removeCrew: async function (crewId) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/teams/' + crewId, HTTP_HEADERS.header());
            return response;
        }
    },
    organizations: {
        getAllOrganizations: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/organizations', HTTP_HEADERS.header());
            return response;
        },
        editOrganization: async function (organizationToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/organizations/' + organizationToEdit._id, organizationToEdit, HTTP_HEADERS.header());
            return response;
        },
    },
    posts: {
        getAllPosts: async function () {
            const response = await axios.get(BASE_URL + '/api/v3/admin/posts', HTTP_HEADERS.header());
            return response;
        },
        addPost: async function (postToAdd) {
            const response = await axios.post(BASE_URL + '/api/v3/admin/posts', postToAdd, HTTP_HEADERS.header());
            return response;
        },
        editPost: async function (postToEdit) {
            const response = await axios.put(BASE_URL + '/api/v3/admin/posts/' + postToEdit._id, postToEdit, HTTP_HEADERS.header());
            return response;
        },
        removePost: async function (postId) {
            const response = await axios.delete(BASE_URL + '/api/v3/admin/posts/' + postId, HTTP_HEADERS.header());
            return response;
        }
    } ,
    auth: {
        login: async function (loginObject) {
            const response = await axios.post(BASE_URL + '/api/v2/auth/login/', loginObject);
            return response;
        }
    }
}

export default AxiosRallyGoAPI;
