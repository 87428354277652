import { IsNullOrEmptyOrUndefined, setErrorMessage } from '../../utils/helpers/validatorHelper.js';
import { actions } from '../actions.js';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    loading: true,
    error: null,
    actionError: null,
    filteredData: null,
    data: null,
    modalIsOpen: false,
    selectedCrew: {
        _id: null,
        name: '',
        desc: '',
        avatar: '',
        city: '',
        country: '',
        users: [],
        members: [],
        contacts: [],
    }
};

export const crew = (state, action) => {
    switch (action.type) {
        case actions.crew.GET_ALL_CREW:
            return {
                ...state,
                filteredData: [],
                data: [],
                selectedCrew: {
                    _id: null,
                    name: '',
                    desc: '',
                    avatar: '',
                    city: '',
                    country: '',
                    users: [],
                    members: [],
                    contacts: [],
                },
                loading: true,
                error: null,
                actionError: null,
                modalIsOpen: false,
            };
        case actions.crew.SET_CREW_MEMBERS:
            return {
                ...state,
                members: !IsNullOrEmptyOrUndefined(action.payload) > 0 ? state.data?.filter(item => item._id === action.payload)[0]?.members : state.data.members
            };
        case actions.crew.SET_CREW_CONTACTS:
            return {
                ...state,
                contacts: !IsNullOrEmptyOrUndefined(action.payload) > 0 ? state.data?.filter(item => item._id === action.payload)[0]?.contact : state.data.contact
            };
        case actions.crew.SET_ALL_CREW:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: null,
                actionError: null,
            }
        case actions.crew.SET_CREW:

            return {
                ...state,
                selectedCrew: action.payload,
            }
        case actions.crew.RESET_CREW:
            return {
                ...state,
                selectedCrew: {
                    _id: null,
                    name: '',
                    desc: '',
                    avatar: '',
                    city: '',
                    country: '',
                    users: [],
                    members: [],
                    contacts: [],
                }
            }
        case actions.crew.REMOVE_CREW_MEMBER:
            return {
                ...state,
                data: state.data?.filter((item) => item._id !== action.payload.crew._id)[0]?.members?.filter(m => m._id !== action.payload.member._id),
                filteredData: state.data?.filter((item) => item._id !== action.payload.crew._id)[0]?.members?.filter(m => m._id !== action.payload.member._id),
                members: state.members?.filter(m => m._id !== action.payload.member._id),
            }
        case actions.crew.REMOVE_CREW_CONTACT:
            return {
                ...state,
                data: state.data?.filter((item) => item._id !== action.payload.crew._id)[0]?.contact?.filter(m => m._id !== action.payload.contacts._id),
                filteredData: state.data?.filter((item) => item._id !== action.payload.crew._id)[0]?.contact?.filter(m => m._id !== action.payload.contacts._id),
                contacts: state.contacts?.filter(c => c._id !== action.payload.contact._id),
            }
        case actions.crew.SEARCH_CREW:
            return {
                ...state,
                filteredData: action.payload.length > 2 ? state.data.filter(item =>
                    item.name?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.desc?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.avatar?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.city?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.country?.toLowerCase().includes(action.payload.toLowerCase())
                    || item.members.some(t => t.fname?.toLowerCase().includes(action.payload.toLowerCase())
                        || t.lname?.toLowerCase().includes(action.payload.toLowerCase())
                        || t.pos?.toLowerCase().includes(action.payload.toLowerCase())
                    ) ||
                    item.contact.some(t => t.type?.toLowerCase().includes(action.payload.toLowerCase())
                        || t.value?.toLowerCase().includes(action.payload.toLowerCase()))
                ) : [...state.data]
            }
        case actions.crew.OPEN_CLOSE_MODAL:
            return {
                ...state,
                modalIsOpen: !state.modalIsOpen,
            };
            case actions.crew.CREW_ADDED_SUCCESS:
                toast.success("Crew added with success!");
                return {
                    ...state,
                    data: [...state.data, action.payload],
                    filteredData: [...state.data, action.payload],
                    modalIsOpen: false,
                }
            case actions.crew.CREW_UPDATED_SUCCESS:
                toast.success("Crew updated with success!");
                return {
                    ...state,
                    data: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                    filteredData: state.data.map(item => item._id === action.payload._id ? action.payload : item),
                    modalIsOpen: false,
                }
            case actions.crew.CREW_REMOVED_SUCCESS:
                toast.success("Crew removed with success!");
                return {
                    ...state,
                    data: state.data.filter((item) => item._id !== action.payload),
                    filteredData: state.data.filter((item) => item._id !== action.payload),
                    modalIsOpen: false,
                }
        case actions.global.RESET_APP:
            return {
                ...INITIAL_STATE,
            };
        case actions.crew.CREW_ERROR:
            toast.error("Internal Server Error: "+IsNullOrEmptyOrUndefined(action.payload) ? null : setErrorMessage(action.payload, false));
            return {
                ...state,
                loading: false,
                error: IsNullOrEmptyOrUndefined(action.payload)? null : setErrorMessage(action.payload, false)
            };
        case actions.crew.ACTION_ERROR:
            if(!IsNullOrEmptyOrUndefined(action.payload)) toast.error(setErrorMessage(action.payload, false));
            return {
                ...state,
                 loading: false,
                actionError: IsNullOrEmptyOrUndefined(action.payload)? null : setErrorMessage(action.payload, false)
                    };
        default:
            return {
                ...state
            }

    }
}