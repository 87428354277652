import React from 'react';
import Modal from 'react-modal';
import store from '../../store/store.js';
import { actions } from '../../store/actions.js';
import { modalStyles } from '../../utils/helpers/modalHelper.js';
import { IsNullOrEmptyOrUndefined } from '../../utils/helpers/validatorHelper.js';
import '../shared/common.scss';


Modal.setAppElement('#root')

class UserOrganizations extends React.Component {

    constructor(props) {
        super();
        this.state = {
            userOrganizations:IsNullOrEmptyOrUndefined(props.data)? [] : props.data,
            organizations:IsNullOrEmptyOrUndefined(props.organizations)? [] : props.organizations,
            updatingOrganization:{},
            modalIsOpen:false,
            editMode:false,
            modalTitle:'',
            selectedOrganization:{},
          }
          this.inputOrganizations = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userOrganizations: IsNullOrEmptyOrUndefined(nextProps.data)? [] : nextProps.data,
            organizations: IsNullOrEmptyOrUndefined(nextProps.organizations)? [] : nextProps.organizations,
        })
      }

      showOrganization(id) {
          this.setState(prevState => {
            const selectedOrganization = prevState.userOrganizations.filter(organization =>
                organization.organizationToUpdate._id === id
            )[0].organizationToUpdate

            return { selectedOrganization };
        })

        this.openModal('Editing exisitng Organization', true)
      }

      openModal(modalTitle, editingMode) {
        
        if(!editingMode) {
            this.setState({editMode:false});
        } else {
            this.setState({ editMode:true });
        }

        this.setState({
            modalTitle: modalTitle
        })
         this.setState({
             modalIsOpen:true
         })
      }

      closeModal() {
        this.setState({
            modalIsOpen:false,
            selectedOrganization:{}
        })
      }

      addOrganization() {
        if(this.inputOrganizations.current.value !== -1) {
        let updatingOrganization = this.state.updatingOrganization;

        updatingOrganization.users.push(this.props.userId);
        store.dispatch(actions.user.setUserOrganizations(updatingOrganization))

        this.closeModal();
        }
      }

      removeOrganization() {
        let updatingOrganization = this.state.selectedOrganization;

        const users = updatingOrganization.users.filter(user => user !== this.props.userId);
        updatingOrganization.users = users;
        store.dispatch(actions.user.setUserOrganizations(updatingOrganization))

        this.closeModal();
      }

      renderOrganizationsList = () => this.state.userOrganizations.map((organization) => (
        <tr id={organization?.organizationToUpdate?._id} key={organization?.organizationToUpdate?._id} onClick={() => this.showOrganization(organization.organizationToUpdate?._id)}>
            <td>{organization?.organizationToUpdate?.name}</td>
            <td>{organization.organizationToUpdate?.type}</td>
            <td className="max-width-300">{organization.organizationToUpdate?.desc}</td>
        </tr>
      )); 

      handleChange(e) {
        this.setState({
          updatingOrganization:this.state.organizations.filter(organization => organization._id === e.target.value)[0]
        });

      }

    render() {
    return (
<div>

<Modal
          isOpen={this.state.modalIsOpen}
          style={modalStyles}
        >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{this.state.modalTitle}</h6>
                    <div className="mT-30">
                        <div className="form-group">
                                <label htmlFor="inputOrganizations">Organizations</label>
                                <select id="inputOrganizations" className="form-control" ref={this.inputOrganizations} onChange={(e) => this.handleChange(e)} disabled={this.state.editMode} defaultValue={this.state.selectedOrganization?._id}>
                                    <option key={-1} value={-1} >--select organization--</option>
                                    {this.state.organizations?.map((item, index) => 
                                        <option key={index} value={item._id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="control-buttons justify-to-right">
                            <button className={this.state.editMode? "btn btn-danger" : "hidden" } onClick={() => this.removeOrganization()}>Remove</button>
                                <button className="btn btn-primary" onClick={() => this.closeModal()}>Cancel</button>
                                <button type="submit" className={!this.state.editMode? "btn btn-primary" : "hidden" } onClick={() => this.addOrganization()}>Save</button>
                            </div>
                    </div>
                </div>
            </Modal>

    <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="bgc-white bd bdrs-3 p-20 mB-20">
                    <div className="control-panel">
                        <div className="control-buttons">
                <button type="button" className="btn cur-p btn-dark" onClick={() => this.openModal('Adding new organization', false)}>Add</button>
                </div>
                </div>
                  <table id="dataTable" className="table table-striped table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                    {this.renderOrganizationsList()}
                      </tbody>
                    </table>
               </div>
              </div>
            </div>


    </div>
    </div>
    )
 }
}

export default UserOrganizations;